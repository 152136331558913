import _ from 'lodash';
const accountEntityEnabled = 1; // 会计主体启用状态
export const tableConfig = {
    name: 'priceDetail',
    selection: false,
    height: 600,
    columns: [
        {
            prop: 'contractCode',
            label: '合同编号',
            disabled: true,
            width: 155
        },
        {
            prop: 'mipContractCode',
            label: '法务合同编码',
            disabled: true,
            width: 155
        },
        {
            label: '合同名称',
            prop: 'contractName',
            type: 'input',
            mustFill: true,
            width: 150
        },
        {
            label: '会计主体',
            prop: 'accountEntityName',
            value: 'accountEntityName',
            mustFill: true,
            type: 'advance',
            width: 200,
            // advanceConfig配置搜索条件，tableConfig配置弹窗中的表格列信息
            advanceConfig: [
                { prop: 'operatingUnitName', value: '会计主体名称' },
                { prop: 'operatingUnitId', value: '会计主体编码' }
            ],
            tableConfig: [
                { prop: 'operatingUnitId', value: '会计主体编码' },
                { prop: 'operatingUnitName', value: '会计主体名称' }
            ],
            prefix: '/api-lcrm',
            // method: 'post',
            advanceUrl: `/efOrganRelations`,
            cbParams: ['operatingUnitId#accountEntityId', 'operatingUnitName#accountEntityName', 'legalEntityName'], // legalSysId: 会计主体id
            beforeRequest: (params) => {
                if (params.operatingUnitName) {
                    return params;
                }
                const inputKey = params.operatingUnitId?.trim();
                const reg = new RegExp(/^-?\d+$/); // 数字正则
                if (!reg.test(inputKey)) {
                    params.operatingUnitName = inputKey;
                    params.operatingUnitId = null;
                } else {
                    params.operatingUnitId = inputKey;
                    params.operatingUnitName = null;
                }
                params.enabledFlag = accountEntityEnabled; // 启用状态
                return params;
            },
            afterResponse: (res) => {
                if (res && res.data && Array.isArray(res.data.list)) {
                    res.data.list = res.data.list.filter(item => !_.isNil(item));
                }
                return _.cloneDeep(res);
            }
            // sort: true
            // advanceCode: 'advanceCode'
        },
        {
            label: '生效时间',
            prop: 'validTime',
            type: 'date',
            // sort: true,
            mustFill: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            width: 154
        },
        {
            label: '失效时间',
            prop: 'failTime',
            type: 'date',
            // sort: true,
            mustFill: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            width: 154
        },
        {
            label: '业务类别',
            prop: 'businessType',
            type: 'select',
            multiple: true,
            optionsKey: 'SYS_BMS_BUSI_TYPE',
            mustFill: true,
            collapseTags: true,
            slot: true,
            width: 180
        },
        {
            label: '仓储运作类型',
            prop: 'customerType',
            type: 'select',
            placeholder: '',
            optionsKey: 'CONTRACT_CUSTOMER_TYPE',
            // mustFill: true,
            width: 110
        },
        {
            label: '油价详细条款说明',
            prop: 'conditionsDetail',
            type: 'slot',
            width: 130,
            mustFill: true,
            slot: true,
            editSlot: true
        },
        {
            label: '合同状态',
            prop: 'contractStatus',
            type: 'select',
            disabled: true,
            placeholder: ' ',
            optionsKey: 'SYS_CR_CONTRACT_STATUS',
            width: 90
        }
    ]
};
