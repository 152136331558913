<template>
    <div class="add-oil-detail-dialog">
        <el-dialog
            :visible="true"
            width="600px"
            title="油价详细条款说明："
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <el-form ref="ruleForm" :model="form" label-width="0" :rules="rules">
                <el-form-item prop="conditionsDetail">
                    <el-input
                        v-model="form.conditionsDetail"
                        :maxlength="4000"
                        type="textarea"
                        :rows="5"
                        :show-word-limit="true"
                        :clearable="true"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <lots-button size="mini" @click="emit('handleClose', false)">取消</lots-button>
                <lots-button type="primary" size="mini" @click="confirm">确认</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, onMounted } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
export default {
    components: {
        lotsButton
    },
    props: {
        row: {
            type: Object,
            default: () => { return {}; }
        }
    },
    setup(props, setupContext) {
        const { emit } = setupContext;
        const rules = {
            conditionsDetail: [{ required: true, message: '详细条款说明不能为空', trigger: 'change' }]
        };
        const form = reactive({
            conditionsDetail: ''
        });
        onMounted(() => {
            form.conditionsDetail = props.row.conditionsDetail;
        });
        const confirm = () => {
            setupContext.refs.ruleForm.validate((flag) => {
                if (flag) {
                    emit('confirm', form.conditionsDetail);
                }
            });
        };
        return {
            emit,
            rules,
            form,
            confirm
        };
    }
};
</script>
