<!-- eslint-disable max-lines -->
<template>
    <div class="contract-manage">
        <Paper class="contract-manage-paper">
            <el-tabs
                class="contract-list-tab-pane"
                v-model="activeTabs"
                @tab-remove="removeTab"
                @tab-click="switchTabs">
                <!-- 合同列表页 -->
                <el-tab-pane
                    label="合同组列表"
                    name="theList"
                    ref="myPane"
                    v-loading="holePageLoading">
                    <list-layout-paper>
                        <template slot="header-search" class="searchBox">
                            <search-box
                                ref="searchBox"
                                :search-key="config.name"
                                :fields="config.searchFields"
                                :showResetBtn="true"
                                @search-change="contractList.searchList"
                            />
                        </template>
                        <template slot="header-button">
                            <lotsButton
                                v-has="authorityCode.addBatch"
                                type="primary"
                                size="mini"
                                @click="addNew"
                                title="批量新增">
                                <span class="icon iconfont">&#xe66a;</span> 批量新增
                            </lotsButton>
                            <lotsButton
                                v-has="authorityCode.replenishBatch"
                                type="primary"
                                size="mini"
                                @click="addReplenishOrStop(STATE_REPLENISH)"
                                title="批量变更">
                                <span class="icon iconfont">&#xe66a;</span> 批量变更
                            </lotsButton>
                            <lotsButton
                                v-has="authorityCode.stopBatch"
                                type="primary"
                                size="mini"
                                @click="addReplenishOrStop(STATE_STOP)"
                                title="批量终止">
                                <span class="icon iconfont">&#xe66a;</span> 批量终止
                            </lotsButton>
                            <lotsButton
                                type="primary"
                                size="mini"
                                title="刷新"
                                @click="refresh"
                                plain>
                                <span class="icon iconfont icon-refresh"></span>
                            </lotsButton>
                            <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                                <el-button
                                icon="iconfont icon-list_install"
                                type="primary"
                                size="mini"
                                :title="'列配置'"
                                @click="showColumnConfigDialog"
                                plain />
                            </el-tooltip>
                        </template>
                        <!-- 表格 -->
                        <template v-slot:list="{ tableHeight }">
                            <table-box
                                ref="clientOrder"
                                :height="tableHeight"
                                v-loading="contractList.tableLoading"
                                :selection="config.selection"
                                :index="config.index"
                                :columns="config.tableData.columns"
                                :rows="contractList.totalData"
                                :actions="config.actions"
                                @action-click="actionClick"
                                @row-dblclick="rowDBclick"
                                @selection-change="handleSelectionChange">
                                <template v-slot:col-append="{ col, row }">
                                    <div v-if="col.prop === 'businessType'">{{col.prop === 'businessType' ? row.busiLabelList:row.businessType}}</div>
                                </template>
                            </table-box>
                        </template>
                        <template slot="footer">
                            <lots-pagination
                                @size-change="contractList.sizeChange"
                                :current-page.sync="contractList.pageNo"
                                @current-change="contractList.pageChange"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="contractList.pageTotal" >
                            </lots-pagination>
                        </template>
                    </list-layout-paper>
                </el-tab-pane>
                <!-- 详情页签 -->
                <el-tab-pane
                    closable
                    v-for="item in tabList"
                    :key="item.code + item.detailPageState"
                    :label="item.contractMark"
                    :name="item.code + item.detailPageState">
                    <price-main
                        v-if="item.detailPageState === 'price'"
                        :detailData="item.datailData"
                        :activeTabs="activeTabs">
                    </price-main>
                    <detail-tab
                        v-else
                        :ref="`datailTabs${item.code + item.detailPageState}`"
                        :code="item.code"
                        :detailPageState="item.detailPageState"
                        :disableBus="item.buttonData"
                        :detailData="item.datailData"
                        @save-data="detailSaveData"
                        @add-new="addNew"
                        @refrash-data="refrashData"
                        @close-page="removeTab"
                        @subTabDbClick="rowDBclick">
                    </detail-tab>
                </el-tab-pane>
            </el-tabs>
            <!-- 表格列配置弹框 -->
            <column-config
                :pageID="config.name"
                :initColumns="config.tableData.columns"
                ref="otpColumnConfig"
                @header-change="config.tableData.columns = $event"
            />
        </Paper>
        <!-- 变量变更、终止弹框 -->
        <advance-multi-dialog
            ref="advanceMultiDialog"
            @confirm="addReplenishOrStopSure"
            @getCheckedList="detailCheckedList">
        </advance-multi-dialog>
    </div>
</template>
<script>
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import detailTab from './detail/Detail.vue';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import { groupInfoPage, groupInfoRemove, supplementaryCreateBatch } from '@mdm/api/contractManage/contractGroupListApi.js';
import { groupInfoGetById } from '@mdm/api/contractManage/groupDetailApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import { ref, reactive, onMounted, onUnmounted } from '@vue/composition-api';
import { Message } from 'element-ui';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import columnConfig from '@/components/lots/columnConfig/Index';
import {
    STATE_MAIN,
    STATE_NEW,
    STATE_REPLENISH,
    STATE_STOP,
    CONTRACT_CHARACTER
} from '@/modules/mdm/constant/contractManage.js';
import store from '@/store';
import _ from 'lodash';
import Bus from '@/utils/bus.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import advanceMultiDialog from '@/components/lots/otpAdvanceMultiDialog';
export default {
    name: 'tianfuBankContract',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        tableBox,
        lotsPagination,
        lotsButton,
        columnConfig,
        detailTab,
        advanceMultiDialog
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractGroupManage);
        Bus.$on('viewContractGroupDetail', ({ contractGroupCode }) => { // 从mip审批流程页跳转
            groupInfoPage({ contractGroupCode }).then(res => {
                if (+res.code === 0 && res.data.list && res.data.list.length) {
                    rowDBclick(res.data.list[0]);
                }
            });
        });
        // 组件卸载时，取消订阅
        onUnmounted(() => {
            Bus.$off('viewContractGroupDetail');
        });
        const { user } = store.getters;
        const tenantCode = store.getters.currentTenant.tenantCode;
        let busiTypeList = {};
        onMounted(async () => {
            const queryParams = {
                createTime: utils.defaultDate('29')
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
            const dictData = await getDictDataApi({ dictCodes: 'SYS_BMS_BUSI_TYPE,SYS_CONTRACT_PRODUCT_TYPE' });
            busiTypeList = dictData.data['SYS_BMS_BUSI_TYPE'];
        });
        class ContractListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(v => {
                        const busiList = v.businessType ? v.businessType.split(',') : [];
                        const busiLabelList = busiList.map(v2 => busiTypeList[v2]);
                        v.busiLabelList = busiLabelList.join();
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const contractList = new ContractListClass({
            Api: {
                // listApi: getContractInfoList, // 查询接口
                listApi: groupInfoPage, // 查询接口
                deleteApi: groupInfoRemove // 删除行接口
            }
        });
        const searchDataFormat = (condition) => {
            if (condition.createTime && condition.createTime.length) {
                condition.startTime = utils.formatDateTime(condition.createTime[0]);
                condition.endTime = utils.formatDateTime(condition.createTime[1]);
                Reflect.deleteProperty(condition, 'createTime');
            }
            if (condition.validTime && condition.validTime.length) {
                condition.startValidTime = utils.formatDateTime(condition.validTime[0]);
                condition.endValidTime = utils.formatDateTime(condition.validTime[1]);
                Reflect.deleteProperty(condition, 'validTime');
            }
            if (condition.failTime && condition.failTime.length) {
                condition.startFailTime = utils.formatDateTime(condition.failTime[0]);
                condition.endFailTime = utils.formatDateTime(condition.failTime[1]);
                Reflect.deleteProperty(condition, 'failTime');
            }
            if (condition.selectList && condition.selectList.length) {
                condition.createUserCode = condition.selectList[0].userCode;
            }
            Reflect.deleteProperty(condition, 'selectList');
            Reflect.deleteProperty(condition, 'userName');
            condition.userCode = user.userCode;
            condition.isNew = condition.isNew ? +condition.isNew : null;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return condition;
        };
        const config = reactive(configStatic);
        const holePageLoading = ref(false);
        const selectedList = ref([]);
        const refresh = () => {
            contractList.getList();
        };
        const handleSelectionChange = (data) => {
            selectedList.value = data;
        };
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        const activeTabs = ref('theList');
        const removeTab = (targetName) => { // 删除详情标签
            if (activeTabs.value === targetName) {
                activeTabs.value = 'theList';
            }
            tabList.value = tabList.value.filter(
                (tab) => (tab.code + tab.detailPageState) !== targetName
            );
        };
        const switchTabs = (data) => {
            const comKey = `datailTabs${data.name}`;
            if (setupContext.refs[comKey] && setupContext.refs[comKey].length) {
                const { activeName } = setupContext.refs[comKey][0];
                setupContext.refs[comKey][0].handleClickTab({ name: activeName }); // 解决切换详情页tab子页签编辑表格错位问题
            }
        };
        const tabList = ref([]); // 打开的详情tab页集
        const currDetailData = ref({});
        const formatData = (data) => { // 特殊数据格式处理函数
            if (data.businessType && typeof data.businessType === 'string') {
                data.businessType = data.businessType.split(',') || [];
            }
            return data;
        };
        // 双击表格
        const rowDBclick = (row, pageType = STATE_MAIN) => {
            let status = '';
            const name = '合同组';
            if (String(row.contractGroupCharacter) === CONTRACT_CHARACTER.TERMINATION || pageType === STATE_STOP) { // 5终止协议
                status = STATE_STOP; // 'stop' 终止
            } else if (String(row.contractCharacter) === CONTRACT_CHARACTER.REPLENISH || pageType === STATE_REPLENISH) { // 3补充协议  这里按钮都和主合同的一样，所以就改下标题和状态就ok
                status = STATE_REPLENISH; // 'replenish'
            } else {
                status = STATE_MAIN; // 'main'
            }
            // 滚动到顶部
            const element = document.getElementsByClassName('app-main');
            element[0].scrollTo(0, 0);
            currDetailData.value = formatData(row);
            /* contractCharacter: 合同性质：1 新签，2 续签，3 补充协议，4 预签， 5 终止协议 */
            /* status: 合同tab页状态: 'stop','replenish','otherChange','main' */
            const code = currDetailData.value.contractGroupCode; // 合同组编码
            newDetailPage(code, currDetailData.value, name, status);
        };

        /**
     * @description: 开详情页通用部分代码
     * @param {String} code 页面编码
     * @param {Object} data 页面数据对象
     * @param {String} name 页面名称信息
     * @param {String} state 页面状态  合同tab页状态
     *  */
        // eslint-disable-next-line complexity
        const newDetailPage = (code, data, name, state) => {
            data.contractGroupStatus = data['contractGroupStatus'] ? data['contractGroupStatus'] : 0;
            data.contractSource = data.contractSource ? data.contractSource : '1'; // 新增时默认为1：CRM
            data.oilLinkedProportion = data.oilLinkedProportion
                || ((['4', '5'].includes(String(data.contractCharacter)) || data.contractSource !== '1') ? '0' : '');
            const judge = tabList.value.filter((item) => (item.code + item.detailPageState) === code + state);
            // data.contractAccountOf = data.contractAccountOf || 0;
            if (judge.length === 0 && tabList.value.length === config.PANELMAX) {
                Message.warning('已超出8个详情页，请关闭后重新打开');
            } else {
                const dataGather = {
                    contractMark: name + code.substring(code.length - config.LASTFOUR),
                    code: code,
                    datailData: _.cloneDeep(data),
                    detailPageState: state
                };
                if (code === '0000') {
                    dataGather.contractMark = name;
                }
                if (judge.length === 0) {
                    // 第一次打开
                    tabList.value.push(dataGather);
                } else { // 已经打开的，直接找到该页面
                    const index = tabList.value.findIndex(v => (v.code + v.detailPageState === judge[0].code + judge[0].detailPageState));
                    if (state !== STATE_NEW) { // 非新增页面，刷新页面数据 (新增页面，不覆盖已填写的数据， )
                        tabList.value[index] = dataGather;
                    }
                }
                activeTabs.value = code + state; // tabs切换到对应详情页
                switchTabs({ name: activeTabs.value }); // 解决切换详情页tab子页签编辑表格错位问题
            }
        };
        const actionClick = (event, row) => {
            if (event === 'delItem') {
                contractList.deleteRow(row);
            }
        };
        const detailSaveData = (contractData, detailPageState) => {
            if (detailPageState === STATE_NEW) { // 详情页面新增合同保存触发，保存成功后重写页面信息(新增合同或补充协议)
                const handleTabItem = tabList.value.find(item => {
                    return item.detailPageState === STATE_NEW;
                });
                handleTabItem.code = contractData.contractGroupCode; // 合同组编码
                handleTabItem.detailPageState = STATE_MAIN;
                rowDBclick(contractData, STATE_MAIN);
            }
        };
        const addNew = (data = {}) => { // 新增合同组信息
            const newData = {
                ...data,
                contractGroupCharacter: '1', // 新签
                contractGroupStatus: '0', // 草稿
                stampMode: '1', // 电子
                productType: '2', // 产品类型：普通合同
                oilIsGroupCustomer: '0' // 内部
            };
            let passFlag = true;
            tabList.value.forEach(item => {
                if (item.detailPageState === STATE_NEW) {
                    passFlag = false;
                    Message.warning('存在新增未保存的草稿合同，请先保存或关闭草稿后再新建新合同');
                    setTimeout(() => {
                        activeTabs.value = item.code + item.detailPageState;
                    }, 1000);
                }
            });
            if (passFlag) {
                newDetailPage('0000', newData, '新增合同组', STATE_NEW);
            }
            return passFlag;
        };
        const refrashData = (data) => {
            rowDBclick(data);
        };
        const addType = ref(STATE_REPLENISH);
        const addReplenishOrStop = (type) => { // 批量变更
            addType.value = type;
            const newAdvanceConfig = reactive({ ...config.searchContractParams, advanceLabel: `请选择要${addType.value === STATE_REPLENISH ? '变更' : '终止'}的主合同` });
            setupContext.refs.advanceMultiDialog.show(true, newAdvanceConfig, {});
        };
        const addReplenishOrStopSure = async(data) => {
            if (!data.length) { return false; }
            // 调接口，生成补充协议/终止协议合同组
            const contractCodeList = data.map(item => {
                return item.contractCode;
            });
            const params = {
                contractCodeList,
                contractCharacter: addType.value === STATE_REPLENISH ? CONTRACT_CHARACTER.REPLENISH : CONTRACT_CHARACTER.TERMINATION
            };
            const res = await supplementaryCreateBatch(params);
            if (+res.code === 0) {
                Message.success('创建成功');
                res.data && res.data.id && groupInfoGetById({ id: res.data.id }).then(res => {
                    if (+res.code === 0) {
                        const newPageData = { ...res.data };
                        newPageData.contractGroupCharacter = newPageData.contractCharacter = addType.value === STATE_REPLENISH ? CONTRACT_CHARACTER.REPLENISH : CONTRACT_CHARACTER.TERMINATION;
                        rowDBclick(newPageData, addType.value);
                    }
                });
            }
        };
        const detailCheckedList = () => {
            // 123
        };
        return {
            contractList,
            config,
            selectedList,
            refresh,
            handleSelectionChange,
            otpColumnConfig,
            showColumnConfigDialog,
            activeTabs,
            removeTab,
            switchTabs,
            tabList,
            rowDBclick,
            currDetailData,
            actionClick,
            detailSaveData,
            addNew,
            refrashData,
            addReplenishOrStop,
            holePageLoading,
            tenantCode,
            utils,
            authorityCode,
            addReplenishOrStopSure,
            STATE_REPLENISH,
            STATE_STOP,
            detailCheckedList
        };
    }
};
</script>
<style lang="less">
.contract-manage {
    width: 100%;
    .contract-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }
    .contract-list-tab-pane.el-tabs {
        width: 100%;
        height: 100%;
        & > .el-tabs__content {
            height: calc(100% - 40px - 14px);
            overflow: auto;
            & > .el-tab-pane {
                overflow: auto;
                padding-right: 5px;
                height: 100%;
                .list-main {
                    .table-box {
                        height: 100%;
                    }
                }
            }
        }
    }
    .flex-layout .list-main {
        overflow: auto;
    }
    .flex-layout .table {
        overflow-y: auto;
    }
    .list-header {
        .contract-manage-list-dropdown.el-dropdown {
            color: #fff;
        }
    }
    .layout-content.contract-manage-paper {
        padding: 0px 20px 20px;
        .el-table .cell.el-tooltip {
            &>div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .list-layout-wrapper {
        padding: 0px;
    }
}
.column-config_title {
    font-size: 14px;
}
</style>
