<template>
    <div class="update-batch-dialog">
        <el-dialog
            :visible="true"
            width="900px"
            title="批量更新附件"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            请选择需要批量更新的附件：
            <table-box
                ref="filesTableBox"
                :height="400"
                :selection="true"
                :columns="newColumns"
                :rows="subTabTable.totalData"
                :actions="actions"
                @action-click="actionClick"
                @selection-change="handleSelectionChange">
            </table-box>
            <lots-pagination
                @size-change="subTabTable.sizeChange"
                :current-page.sync="subTabTable.pageNo"
                @current-change="subTabTable.pageChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="subTabTable.pageTotal" >
            </lots-pagination>
            <span slot="footer">
                <lots-button size="mini" @click="emit('handleClose', false)">取消</lots-button>
                <lots-button type="primary" size="mini" @click="confirm" :loading="confirmLoading">确认</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import store from '@/store/index';
import { tableConfig } from '../config.js';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { Message } from 'element-ui';
import { contractFilesList, contractFilesView } from '@mdm/api/contractManage/contractFootPageApi.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
const btnPermissionsArr = store.getters.btnPermissions;
const { user } = store.getters;
export default {
    components: {
        lotsButton,
        tableBox,
        lotsPagination
    },
    props: {
        FilesDetailClass: {
            type: Function,
            default() {
                return {};
            }
        },
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const { emit } = setupContext;
        const authorityCode = reactive(btnAuthority.contractManage);
        const config = reactive(tableConfig);
        const newColumns = computed(() => {
            const newArr = config.columns.map(item => {
                if (item.prop === 'accessoryName') {
                    return {
                        ...item,
                        type: null
                    };
                } else {
                    return item;
                }
            });
            return newArr;
        });
        const actions = reactive({
            fixedWidth: 100,
            list: [
                {
                    label: '预览',
                    event: 'viewFile',
                    displayRule: (row) => {
                        return !!row.id && +props.detailData.contractCharacter !== 4 && btnPermissionsArr.includes(authorityCode.subFileView) && !props.readonly;
                    }
                }
            ]
        });
        const subTabTable = new props.FilesDetailClass({
            Api: {
                listApi: contractFilesList
            }
        });
        const confirmLoading = ref(false);
        const confirm = () => {
            if (!selectedRows.value.length) {
                return Message.warning('请至少选择一个附件');
            }
            emit('confirm', selectedRows.value);
        };
        const selectedRows = ref([]);
        const handleSelectionChange = (selection) => {
            selectedRows.value = selection;
        };
        const actionClick = (event, selection) => {
            const { fileId, id, attachmentId, contractName } = selection;
            const params = {
                fileId,
                id,
                attachmentId,
                userCode: user.userCode,
                contractName,
                contractCharacter: props.detailData.contractCharacter + ''
            };
            switch (event) {
            case 'viewFile':
                viewDoc(params);
                break;
            default:
                break;
            }
        };
        // 附件预览
        const viewDoc = (params) => {
            contractFilesView(params).then((res) => {
                if (res && +res.code === 0) {
                    window.open(res.data, '附件预览');
                }
            });
        };
        onMounted(() => {
            subTabTable.getList();
        });
        return {
            emit,
            config,
            subTabTable,
            confirmLoading,
            confirm,
            handleSelectionChange,
            newColumns,
            actions,
            actionClick
        };
    }
};
</script>
<style lang="less">
.update-batch-dialog {
    .el-dialog__body {
        padding: 15px 20px 5px;
    }
    .el-input.el-input-group {
        width: 200px;
    }
}
</style>
