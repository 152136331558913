import store from '@/store/index';
const btnPermissionsArr = store.getters.btnPermissions;
export const config = {
    name: 'contractManage',
    searchFields: [
        {
            name: '合同组编码',
            value: 'contractGroupCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '合同组名称',
            value: 'contractGroupName',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '合同单据编码',
            value: 'contractCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '法务合同编码',
            value: 'mipContractCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '合同组状态',
            value: 'contractGroupStatus',
            isFixed: true,
            optionNum: 'SYS_CR_CONTRACT_STATUS',
            type: 'select',
            span: 3
        },
        {
            name: '创建时间',
            value: 'createTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            span: 8
        },
        {
            name: '创建人',
            value: 'createUserCode',
            isFixed: true,
            type: 'advanceUser',
            span: 3
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '合同组编码',
                minWidth: 160,
                prop: 'contractGroupCode',
                sortable: true
            },
            {
                label: '合同组名称',
                prop: 'contractGroupName',
                minWidth: 160
            },
            {
                label: '合同组性质',
                prop: 'contractGroupCharacter',
                type: 'select',
                optionsKey: 'SYS_WM_CONTRACT_CHARACTER'
            },
            {
                label: '合同组状态',
                prop: 'contractGroupStatus',
                type: 'select',
                optionsKey: 'SYS_CR_CONTRACT_STATUS'
            },
            {
                label: '创建人',
                prop: 'createUserName'
            },
            {
                label: '创建时间',
                prop: 'createTime',
                sortable: true,
                minWidth: 154
            },
            {
                label: '修改人',
                prop: 'updateUserName'
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                sortable: true,
                minWidth: 154
            }
        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6,
    actions: { // 表格操作列的配置
        fixedWidth: 80, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: [
            {
                label: '删除',
                event: 'delItem',
                displayRule: (row) => {
                    return row.contractGroupStatus === 0 && btnPermissionsArr.includes('1000000004');
                }
            }
        ]
    },
    searchContractParams: {
        advanceLabel: '批量变更',
        advanceConfig: [
            { 'prop': 'contractCode', 'value': '主合同编码' },
            { 'prop': 'contractName', 'value': '主合同名称' },
            { 'prop': 'contractGroupCode', 'value': '合同组编码' },
            { 'prop': 'contractGroupName', 'value': '合同组名称' }
        ],
        tableConfig: [
            { displayName: 'contractCode', prop: 'contractCode', value: '合同编码' },
            { prop: 'contractName', value: '合同名称' },
            { prop: 'contractGroupCode', value: '合同组编码' },
            { prop: 'contractGroupName', value: '合同组名称' },
            { prop: 'contractCharacter', value: '合同性质', type: 'select', optionsKey: 'SYS_WM_CONTRACT_CHARACTER' },
            { prop: 'validTime', value: '生效日期' },
            { prop: 'failTime', value: '失效日期' }
        ],
        multiple: true, // 多选
        prefix: '/api-lcrm',
        method: 'post',
        advanceUrl: `/contract/info/page`,
        cbParams: ['contractCode'],
        beforeRequest: (params) => {
            params.contractCode = params.contractCode ? params.contractCode : null;
            params.contractName = params.contractName ? params.contractName : null;
            params.contractCharacters = ['1', '2']; // 合同性质为新签、续签
            params.contractStatus = '2'; // 合同状态为有效
            params.userCode = store.getters.user.userCode;
            return params;
        },
        hideTooltip: true,
        dialogWidth: '1000px'
    }
};
