/*eslint-disable*/
import store from '@/store';
const tenantCode = store.getters.currentTenant.tenantCode;
export const validateNull = (rule, value, callback) => {
    if (!value) { callback(); }// 当不是必填项时，可以提交空字符串
    if (!/^\d+\.?\d{0,2}$/.test(value)) {
        return callback(new Error('请输入数字'));
    }
    callback();
};
export const validateNullAhundred = (rule, value, callback) => {
    if (!/^(\d|[1-9]\d|100)(\.\d{1,2})?$/.test(value)) {
        return callback(new Error('请输入0-100间的数字'));
    }
    callback();
};

const accountFormData = { tenantCode: 'annto', supplierCode: '' };
const originContractFormData = { suppliersCode: '', businessType: '', contractCharacter: '1', contractStatus: '50,60,80' };
const supplierFormData = { tenantCode: tenantCode, businessType: '', servicesType: '' };
const cityFormData = { provinceCode: '' };

const pickerOptions = {
    disabledDate(time) {
        const start = new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0));
        return time.getTime() < start;
    }
};

// 表单配置信息
export const collapse = [
    {
        title: '基本信息',
        ref: 'basicForm',
        formName: 'basicInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            {
                prop: 'contractGroupCode',
                label: '合同组编码',
                placeholder: ' ',
                // type: 'input',
                type: 'custom',
                visible: true,
                disabled: true
            },
            {
                prop: 'contractGroupName',
                label: '合同组名称',
                type: 'input',
                maxlength: 256,
                visible: true,
                rule: [
                    { required: true, message: '合同组名称不能为空', trigger: ['change', 'blur'] }
                ],
            },
            {
                label: '合同组性质',
                prop: 'contractGroupCharacter',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: true,
                rule: [
                    { required: false, message: '合同组性质不能为空', trigger: ['change', 'blur'] }
                ],
                placeholder: ' ',
                optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
            },
            {
                label: '合同组状态',
                prop: 'contractGroupStatus',
                // type: 'select',
                type: 'custom',
                value: '6',
                placeholder: ' ',
                visible: true,
                disabled: true,
                optionsKey: 'SYS_CR_CONTRACT_STATUS'
            },
            {
                label: '开发单位',
                prop: 'developmentUnitName',
                value: 'developmentUnitName',
                type: 'advance',
                visible: true,
                rule: [
                    { required: true, message: '开发单位不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'companyNameCn', 'value': '开发单位名称' },
                    { 'prop': 'companyCode', 'value': '开发单位编码' }
                ],
                tableConfig: [
                    { 'prop': 'companyCode', 'value': '开发单位编码' },
                    { 'prop': 'companyNameCn', 'value': '开发单位名称' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/esCompany/popCompany`,
                cbParams: ['companyCode#developmentUnit', 'companyNameCn#developmentUnitName']
            },
            {
                label: '主要开发人',
                prop: 'salesStaffName',
                value: 'salesStaffName',
                type: 'advance',
                visible: true,
                rule: [
                    { required: true, message: '主要开发人不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusLoginName', 'value': '美信号' }
                ],
                tableConfig: [
                    { 'prop': 'esusId', 'value': 'ID' },
                    { 'prop': 'esusLoginName', 'value': '美信号' },
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusPositionName', 'value': '职位' },
                    { 'prop': 'escoCompanyNameCn', 'value': '部门' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/selectUserCompany`,
                cbParams: ['esusUserNameCn#salesStaffName', 'esusId#salesStaffCode']
            },
            {
                label: '服务经理',
                prop: 'serviceManagerName',
                value: 'serviceManagerName',
                type: 'advance',
                visible: true,
                advanceConfig: [
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusLoginName', 'value': '美信号' }
                    // { 'prop': 'esusId', 'value': 'ID' },
                ],
                tableConfig: [
                    { 'prop': 'esusId', 'value': 'ID' },
                    { 'prop': 'esusLoginName', 'value': '美信号' },
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusPositionName', 'value': '职位' },
                    { 'prop': 'escoCompanyNameCn', 'value': '部门' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/selectUserCompany`,
                cbParams: ['esusUserNameCn#serviceManagerName', 'esusId#serviceManager']
            },
            {
                label: '业务大类',
                prop: 'bigBusinessType',
                type: 'select',
                rule: [
                    { required: true, message: '业务大类不能为空', trigger: 'change' }
                ],
                visible: true,
                placeholder: '',
                optionsKey: 'SYS_LMDM_BUS_TYPE'
            },
            {
                label: '专业公司',
                prop: 'professionalCompany',
                type: 'select',
                visible: true,
                optionsKey: 'SYS_PROFESSIONAL_COMPANY'
            },
            {
                label: '是否云仓',
                prop: 'isCloudWh',
                type: 'select',
                visible: false,
                rule: [
                    { required: true, message: '是否云仓不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SYS_CRM_IS_CLOUND_WH'
            },
            {
                label: '投保方',
                prop: 'insurant',
                type: 'select',
                rule: [
                    { required: true, message: '投保方不能为空', trigger: 'change' }
                ],
                optionsKey: 'SYS_WM_THE_INSURED',
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '签单情况',
                prop: 'isNew',
                type: 'select',
                visible: true,
                rule: [
                    { required: true, message: '签单情况不能为空', trigger: 'change' }
                ],
                optionsKey: 'SYS_LMDM_SIGNING_SITUATION'
            },
            {
                label: '截单日期',
                prop: 'cutOffTime',
                type: 'time',
                timeType: 'date',
                placeholder: '',
                visible: true,
                onlyTime: true,
                valueFormat: 'HH:mm:ss'
            },
            {
                prop: 'contractCount',
                label: '合同份数',
                type: 'input',
                maxlength: 2,
                visible: true,
                rule: [
                    { required: true, message: '合同份数必填', trigger: ['change', 'blur'] }
                ],
            },
            {
                label: '盖章方式',
                prop: 'stampMode',
                type: 'select',
                visible: true,
                disabled: true,
                rule: [
                    { required: true, message: '盖章方式不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SYS_CONTRACT_STAMP_MODE'
            },
            {
                label: '是否生成水印',
                prop: 'ifWatermarking',
                type: 'select',
                visible: false,
                rule: [
                    { required: true, message: '是否生成水印不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SRM_YES_NO'
            },
            {
                label: '不生成水印原因',
                prop: 'noWatermarkingReason',
                type: 'input',
                maxlength: 4000,
                visible: false,
                rule: [
                    { required: true, message: '不生成水印原因不能为空', trigger: 'change' }
                ],
            },
            {
                label: '产品类型',
                prop: 'productType',
                type: 'select',
                visible: true,
                rule: [
                    { required: true, message: '产品类型不能为空', trigger: 'change' }
                ],
                placeholder: ' ',
                optionsKey: 'SYS_CONTRACT_PRODUCT_TYPE',
                disabled: true
            },
            {
                label: '内外部业务',
                prop: 'oilIsGroupCustomer',
                // type: 'select',
                type: 'custom',
                visible: true,
                rule: [
                    { required: true, message: '内外部业务不能为空', trigger: 'change' }
                ],
                optionsKey: 'SYS_WM_IN_OR_OUT_CUSTOMER',
                disabled: true
            },
            {
                label: '合同来源',
                prop: 'contractSource',
                // type: 'select',
                type: 'custom',
                visible: true,
                placeholder: '',
                disabled: true,
                optionsKey: 'CONTRACT_SOURCE'
            }
        ]
    },
    {
        title: '财务信息',
        ref: 'financeForm',
        formName: 'financeInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            {
                label: '合同全账期(天)',
                prop: 'contractAccountOf',
                type: 'input',
                maxlength: 3,
                rule: [
                    { required: true, message: '合同全账期(天)不能为空', trigger: ['change', 'blur'] }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '票后回款(天)',
                prop: 'collectionDays',
                type: 'input',
                maxlength: 3,
                rule: [
                    { required: true, message: '票后回款天数不能为空', trigger: ['change', 'blur'] }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '对账开票(天)',
                prop: 'reconciliationInvoicingDays',
                type: 'input',
                maxlength: 3,
                rule: [
                    { required: true, message: '对账开票(天)不能为空', trigger: ['change', 'blur'] }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '回单收回天数',
                prop: 'receiptRecoveryDays',
                // type: 'input',
                type: 'custom',
                maxlength: 3,
                placeholder: '合同全账期-票后回款-对账开票',
                visible: true,
                disabled: true
            },
            {
                label: '预计业务量(万元)',
                prop: 'expectedTrafficPlan',
                type: 'input',
                maxlength: 14,
                rule: [
                    { required: true, message: '预计业务量不能为空', trigger: ['change', 'blur'] }
                ],
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '上年运作规模(万元)',
                prop: 'operationScale',
                class: 'custom-form-label',
                type: 'input',
                maxlength: 14,
                visible: true
            },
            {
                label: '中标采购毛利率(%)',
                prop: 'grossMarginPlan',
                type: 'input',
                rule: [
                    { required: true, message: '中标采购毛利率不能为空', trigger: ['change', 'blur'] }
                ],
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '中标项目经营中心利润率(%)',
                prop: 'operatGrossMarginPlan',
                type: 'input',
                maxlength: 8,
                rule: [
                    { required: true, message: '中标项目经营中心利润率不能为空', trigger: ['change', 'blur'] }
                ],
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '价格情况',
                prop: 'priceType',
                type: 'select',
                visible: true,
                rule: [
                    { required: true, message: '价格情况不能为空', trigger: 'change' }
                ],
                placeholder: '',
                optionsKey: 'SYS_LMDM_PRICE_LEVEL'
            },
            {
                label: '押金(万)',
                prop: 'deposit',
                type: 'input',
                maxlength: 14,
                rule: [
                    { required: true, message: '押金不能为空', trigger: ['change', 'blur'] }
                ],
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '结算方式',
                prop: 'settlementMode',
                type: 'select',
                rule: [
                    { required: true, message: '结算方式不能为空', trigger: 'change' }
                ],
                optionsKey: 'SYS_SETTLEMENT',
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '承兑比例(%)',
                prop: 'acceptanceRate',
                type: 'input',
                visible: true,
                maxlength: 8,
                rule: [
                    { required: false, message: '承兑比例不能为空', trigger: ['change', 'blur'] }
                ],
            },
            {
                label: '承兑期限(天)',
                prop: 'acceptanceDays',
                type: 'input',
                maxlength: 3,
                rule: [
                    { required: false, message: '承兑期限不能为空', trigger: ['change', 'blur'] }
                ],
                placeholder: '',
                visible: true
            },
            {
                label: '是否代收货款',
                prop: 'paymentCollection',
                type: 'select',
                visible: true,
                rule: [
                    { required: true, message: '是否代收货款不能为空', trigger: 'change' }
                ],
                optionsKey: 'SRM_YES_NO',
                placeholder: '',
                disabled: false
            },
            {
                label: '收款方式',
                prop: 'paymentMethod',
                type: 'select',
                optionsKey: 'SU_PANYMENT_METHOD',
                visible: true
            },
            {
                label: '汇款时间',
                prop: 'remittanceDate',
                type: 'select',
                optionsKey: 'SU_REMITTANCE_DATE',
                rule: [
                    { required: true, message: '汇款时间不能为空', trigger: 'change' }
                ],
                visible: false
            },
            {
                label: '收款至',
                prop: 'receiveTo',
                type: 'select',
                rule: [
                    { required: false, message: '收款至不能为空', trigger: 'change' }
                ],
                optionsKey: 'SU_RECEIVE_TO',
                visible: true
            },
            {
                label: '服务费率(%)',
                prop: 'feeRate',
                type: 'input',
                maxlength: 8,
                rule: [
                    { required: false, message: '服务费率不能为空', trigger: ['change', 'blur'] }
                ],
                visible: true
            },
            {
                label: '服务费支付方式',
                prop: 'feePaymentMode',
                type: 'select',
                rule: [
                    { required: false, message: '服务费支付方式不能为空', trigger: 'change' }
                ],
                optionsKey: 'SU_FEE_PAYMENT_MODE',
                visible: true
            },
            {
                label: '最低服务费(元/票)',
                prop: 'minFeeAmount',
                type: 'input',
                maxlength: 14,
                rule: [
                    { required: false, message: '最低服务费不能为空', trigger: ['change', 'blur'] }
                ],
                placeholder: '',
                visible: false
            }
        ]
    },
    {
        title: '油价联动',
        ref: 'oilForm',
        formName: 'oilPriceInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            {
                label: '是否油价联动',
                prop: 'oilLinkedProportion',
                type: 'select',
                rule: [
                    { required: true, message: '是否油价联动不能为空', trigger: 'change' }
                ],
                optionsKey: 'SRM_YES_NO',
                placeholder: '',
                visible: true,
                disabled: false
            },
            {
                label: '联动类型',
                prop: 'linkageType',
                type: 'select',
                optionsKey: 'SYS_CR_LINKAGE_TYPE',
                placeholder: '',
                rule: [
                    { required: true, message: '联动类型不能为空', trigger: 'change' }
                ],
                visible: true
            },
            {
                label: '触发联动比例(%)',
                prop: 'triggerOilPriceFloat',
                type: 'input',
                maxlength: 5,
                rule: [
                    { required: true, message: '触发联动比例不能为空', trigger: ['change', 'blur'] }
                ],
                visible: true
            },
            {
                label: '运价浮动方式',
                prop: 'freightFloatMode',
                type: 'select',
                optionsKey: 'SYS_CR_FREIGHT_FLOAT_MODE',
                placeholder: '',
                rule: [
                    { required: true, message: '运价浮动方式不能为空', trigger: 'change' }
                ],
                visible: true
            },
            {
                label: '运价联动比例(%)',
                prop: 'freightLinkedScale',
                type: 'input',
                maxlength: 6,
                rule: [
                    { required: true, message: '运价联动比例不能为空', trigger: ['change', 'blur'] }
                ],
                visible: true
            },
            {
                label: '运价浮动系数(%)',
                prop: 'freightFloatCoefficient',
                type: 'input',
                maxlength: 6,
                rule: [
                    { required: true, message: '运价浮动系数不能为空', trigger: ['change', 'blur'] }
                ],
                visible: true
            },
            {
                label: '联动执行时间',
                prop: 'oilLinkageExecutime',
                type: 'select',
                optionsKey: 'SYS_CR_OIL_LINKAGE_EXECUTIME',
                placeholder: '',
                rule: [
                    { required: true, message: '联动执行时间不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '新油价取值方式',
                prop: 'newOilSelectionMethod',
                type: 'select',
                optionsKey: 'SYS_CR_NEW_OIL_SELECTION_METHOD',
                placeholder: '',
                rule: [
                    { required: true, message: '新油价取值方式不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false
            },
            {
                label: '再次触发基准油价',
                prop: 'triggerPriceAgain',
                type: 'select',
                optionsKey: 'SYS_CR_TRIGGER_PRICE_AGAIN',
                placeholder: '',
                rule: [
                    { required: true, message: '再次触发基准油价不能为空', trigger: 'change' }
                ],
                visible: true,
                disabled: false
            }
        ]
    }
];
