import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 合同组列表查询接口
export const groupInfoPage = (data) => {
    return request({
        url: apiCrmHost + '/contract/group/info/page',
        method: 'post',
        data
    });
};
// 删除合同组
export const groupInfoRemove = (data) => {
    return request({
        url: apiCrmHost + '/contract/group/info/remove',
        method: 'delete',
        data
    });
};

// 批量变更/终止
export const supplementaryCreateBatch = (data) => {
    return request({
        url: apiCrmHost + '/supplementary/agreement/createBatch',
        method: 'post',
        data
    });
};
// mip审批页面=合同组附件信息
export const queryContractEnclosureInfo = (data) => {
    return request({
        url: apiCrmHost + '/contract/group/info/queryContractEnclosureInfo',
        method: 'post',
        data
    });
};
