import configurl from '@/config/user.env';
export const importConfig = {
    dialogImportConfig: {
        ImportConfig: {
            baseUrl: configurl.apiUrl + 'api-lcrm/contractEnclosureInfo/upload',
            action: configurl.apiUrl + 'api-lcrm/contractEnclosureInfo/upload',
            multiple: false,
            accept: 'pdf/DOC/DOCX',
            showFileList: true,
            fileType: '盖章文件只能上传pdf、doc、docx文件',
            manualUpload: false
        }
    },
    formConfig: {
        rules: {
            accessoryName: [
                { required: true, message: '请输入附件名称', trigger: 'change' }
            ],
            fileType: [
                { required: true, message: '请选择文件类型', trigger: 'change' }
            ]
        },
        templateAdvance: {
            name: '模板名称',
            value: 'templateName',
            advanceLabel: '模板弹出框',
            advanceConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' }
            ],
            tableConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' },
                { prop: 'templateType', value: '模板类型' },
                { prop: 'templateUrl', value: '预览url' }
            ],
            prefix: 'api-lcrm/',
            advanceUrl: 'contractEnclosureInfo/getTemplate',
            cbParams: ['templateCode#templateCode', 'templateName#templateName', 'templateId#templateId', 'attachmentId#attachmentId', 'draftModel#draftModel']
        }
    }
};
export const tableConfig = {
    selection: false,
    height: 325,
    columns: [
        {
            label: '附件名称',
            prop: 'accessoryName',
            type: 'input',
            mustFill: true,
            width: 150
        },
        {
            label: '合同名称',
            prop: 'contractName',
            mustFill: true,
            width: 150
        },
        {
            label: '文件类型',
            prop: 'fileType',
            type: 'select',
            optionsKey: 'SYS_CR_CONTRACT_FILE_TYPE',
            mustFill: true,
            width: 150
        },
        {
            label: '创建人',
            prop: 'createUserCode'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
        // {
        //     label: '修改人',
        //     prop: 'updateUserName'
        // },
        // {
        //     label: '修改时间',
        //     prop: 'updateTime'
        // }
        // {
        //     label: '附件ID',
        //     prop: 'fileId'
        // },
        // {
        //     label: '文件上传原ID',
        //     prop: 'originalAccessoryLine'
        // },
        // {
        //     label: '业务标识（法务）',
        //     prop: 'attachmentId'
        // },
        // {
        //     label: '内部文件上传url',
        //     prop: 'internalSealFileUrl'
        // },
        // {
        //     label: '外部盖章文件上传url',
        //     prop: 'externalSealFileUrl'
        // }
    ],
    rules: {
        status: [{ required: true, message: '请选择', trigger: 'change' }],
        groupNameCn: [{ required: true, message: '请输入中文名称', trigger: 'blur' }]
    }
};

export const contractAdvanceParams = {
    advanceLabel: '批量更新合同',
    advanceConfig: [
        { 'prop': 'contractNameLike', 'value': '合同名称' },
        { 'prop': 'accountEntityNameLike', 'value': '会计主体名称' }
    ],
    tableConfig: [
        { displayName: 'contractCode', prop: 'contractCode', value: '合同编码' },
        { prop: 'contractName', value: '合同名称' },
        { prop: 'accountEntityName', value: '会计主体' },
        { prop: 'validTime', value: '生效日期' },
        { prop: 'failTime', value: '失效日期' },
        { prop: 'busiLabelList', value: '业务类别' },
        { prop: 'contractStatus', value: '合同状态', type: 'select', optionsKey: 'SYS_CR_CONTRACT_STATUS' }
    ],
    multiple: true, // 多选
    prefix: '/api-lcrm',
    method: 'post',
    advanceUrl: `/contract/info/byContractGroupCode`,
    cbParams: ['contractCode'],
    params: {},
    beforeRequest: (params) => {
        return params;
    },
    afterResponse: (res) => {
        return res;
    },

    hideTooltip: true,
    dialogWidth: '1000px'
};

export const contractTempParams = {
    label: '合同模板',
    prop: 'accountEntityName',
    value: 'accountEntityName',
    mustFill: true,
    type: 'advance',
    width: 200,
    // advanceConfig配置搜索条件，tableConfig配置弹窗中的表格列信息
    advanceConfig: [
        { prop: 'templateCode', value: '模板编码' },
        { prop: 'templateName', value: '模板名称' }
    ],
    tableConfig: [
        { prop: 'templateCode', value: '模板编码' },
        { prop: 'templateName', value: '模板名称' },
        { prop: 'templateType', value: '模板类型' },
        { prop: 'templateUrl', value: '预览URL' }
    ],
    prefix: '/api-lcrm',
    noPage: false,
    method: 'post',
    advanceUrl: `/contractFileTemplateInfo/getTemplate/detail/JDSK`,
    cbParams: ['templateCode', 'templateType', 'templateUrl', 'attachmentId', 'templateId', 'draftModel', 'templateSign',
        'templateName', 'templateProperties', 'templatePropertiesName'], // legalSysId: 会计主体id
    beforeRequest: (params) => {
        params.lcrmFlag = 'LCRM';
        return params;
    },
    afterResponse: (res) => {
        res.data.list = res.data;
        return res;
    }
    // sort: true
    // advanceCode: 'advanceCode'
};
