import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 合同组明细列表查询
export const byContractGroupCode = (data) => {
    return request({
        url: apiCrmHost + '/contract/info/byContractGroupCode',
        method: 'post',
        data
    });
};
// 合同明细列表-单行保存
export const groupInfoSaveContract = (data) => {
    return request({
        url: apiCrmHost + '/contract/group/info/saveContract',
        method: 'post',
        data
    });
};
// 附件明细子页签-批量更新附件
export const enclosureInfoUpdateBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/enclosure/info/updateBatch',
        method: 'post',
        data
    });
};
