<!-- eslint-disable max-lines -->
<template>
    <div class="contract-detail"
        v-loading="detailLoading">
        <!-- 按钮区域 -->
        <div class="detail-toolBar">
            <span
                class="contract-detail-btn"
                v-if="detailPageState !== 'new'"
                v-has="authorityCode.addBatch">
                <lots-button type='primary' @click="addNew">新 增</lots-button>
            </span>
            <span class="contract-detail-btn" v-has="authorityCode.save">
                <lots-button
                    type="primary"
                    :disabled="disableBus['saveHeader']"
                    @click="saveHeader"
                    :loading="detailLoading">保 存</lots-button>
            </span>
            <span v-has="authorityCode.submitMip" v-if="!['new'].includes(detailPageState)" class="contract-detail-btn">
                <lots-button type='primary' :disabled="disableBus['submitIFlow']" :loading="applyIflowLoading" @click="applyIflow" class="bigData-btn-detailPageApplyIflow">提交审批</lots-button>
            </span>
            <span v-has="authorityCode.delete" v-if="!['new'].includes(detailPageState)" class="contract-detail-btn">
                <lots-button type='primary' :disabled="disableBus['delHeader']" @click="deleteHeader" :loading="deleteHeaderLoading">删除</lots-button>
            </span>
            <el-tooltip
                class="item-flow-right"
                effect="dark"
                content="刷新"
                placement="bottom">
                <lots-button
                size="mini"
                icon="el-icon-refresh"
                v-show="code !== '0000'"
                @click="refleshPage"
                :loading="detailLoading"></lots-button>
            </el-tooltip>
            <lots-button
                v-if="detailDataLocal.contractGroupStatus && +detailDataLocal.contractGroupStatus !== 0"
                class="item-flow-right view-flow"
                size="mini"
                type='primary'
                :loading="viewFlowLoading"
                @click="handleViewFlow">流程信息</lots-button>
        </div>
        <!-- 表单信息组件 -->
        <collapse-infos
            ref="upholdHealder"
            :contractCode="detailDataLocal.contractCode"
            :detailData="detailDataLocal"
            :collapse="COLLAPSE"
            :detailPageState="detailPageState"
            :disableBus="disableBus"
            @change="handleInfosChange">
        </collapse-infos>
        <!-- 子页签组件 -->
        <el-collapse
            class="contract-collapse detail-more-collapse"
            value="moreInfo"
            @change="handleClickMoreInfo">
            <el-collapse-item title="合同明细" name="moreInfo" ref="tabs">
                <div class="contract-list-detail el-tabs__content">
                    <!-- 合同明细列表 -->
                    <div class="contract-list-detail-list" :class="{'NEW': detailPageState === 'new', 'view-price': viewPriceSub, 'edit-list': editList && detailPageState !== 'new'}">
                        <div class="list-btn file-detail-btn">
                            <el-button
                                v-has="authorityCode.addContractList"
                                type="primary" icon="el-icon-circle-plus-outline" size="medium"
                                @click="handleAddRow"
                                :disabled="+this.detailDataLocal.contractGroupCharacter !== 1 || ![0, 1].includes(+this.detailDataLocal.contractGroupStatus)">新增
                            </el-button>
                            <lots-button type="primary" icon="el-icon-upload2" size="medium"
                                @click="handleSubmitFW" :loading="submitFWLoading"
                                v-if="detailPageState !== 'new'" :disabled="+this.detailDataLocal.contractGroupStatus !== 6"
                                v-has="authorityCode.pushContractFW">法务归档重试
                            </lots-button>
                        </div>
                        <div class="list-father">
                            <contract-list ref="contractListRef" :detailData="detailDataLocal" @handleCurrentMain="handleCurrentMain" :detailPageState="detailPageState"
                            @handleEdit="handleEdit" @handleCancel="handleCancel"></contract-list>
                        </div>
                    </div>
                    <!-- 合同子页签 -->
                    <div class="contract-list-detail-sub" :class="{'NEW': detailPageState === 'new', 'view-price': viewPriceSub, 'edit-list': editList && detailPageState !== 'new'}">
                        <div class="sub-tab-father">
                            <el-tabs v-model="activeName" @tab-click="handleClickTab">
                                <el-tab-pane
                                    label="附件明细"
                                    name="filesDetail"
                                    v-if="subTabShow.fileDetail">
                                    <file-detail
                                        ref="filesDetailRef"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :detailData="detailDataLocal"
                                        :tableHeight="564">
                                    </file-detail>
                                </el-tab-pane>
                                <el-tab-pane label="客户明细" name="customerDetail" v-if="subTabShow.coustomer">
                                    <customer-detail
                                        ref="customerDetailRef"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :detailData="detailDataLocal"
                                        :tableHeight="564">
                                    </customer-detail>
                                </el-tab-pane>
                                <el-tab-pane label="分公司明细" name="companyDetail" v-if="subTabShow.company">
                                    <company-detail
                                        ref="companyDetailRef"
                                        :detailData="detailDataLocal"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :tableHeight="564">
                                    </company-detail>
                                </el-tab-pane>
                                <el-tab-pane label="油价信息" name="oilDetail" v-if="subTabShow.oilDetail">
                                    <oil-detail
                                        ref="oilDetailRef"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :detailData="detailDataLocal"
                                        :tableHeight="564">
                                    </oil-detail>
                                </el-tab-pane>
                                <el-tab-pane
                                    label="业务税率"
                                    name="rateDetail"
                                    v-if="subTabShow.rate">
                                    <rate-detail
                                        ref="rateDetailRef"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :detailData="detailDataLocal"
                                        :tableHeight="564">
                                    </rate-detail>
                                </el-tab-pane>
                                <el-tab-pane label="预签明细" name="prescreenDetail" v-if="subTabShow.prescreen">
                                    <prescreen-detail
                                        ref="prescreenDetailRef"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :detailData="detailDataLocal"
                                        :tableHeight="564">
                                    </prescreen-detail>
                                </el-tab-pane>
                                <!-- <el-tab-pane
                                    label="价格表"
                                    name="priceDetail"
                                    v-if="subTabShow.price">
                                    <price-detail
                                        ref="priceDetailRef"
                                        :detailData="detailDataLocal"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :mainHeight="489"
                                        :detailHeight="494">
                                    </price-detail>
                                </el-tab-pane> -->
                                <el-tab-pane
                                    label="流程信息"
                                    name="iflowDetail"
                                    v-if="subTabShow.iflow">
                                    <iflow-detail
                                        ref="iflowDetailRef"
                                        :detailData="detailDataLocal"
                                        :activeName="activeName"
                                        :detailPageState="detailPageState"
                                        :actions="actions"
                                        :tableHeight="600">
                                    </iflow-detail>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import FileDetail from '@/modules/mdm/views/contractModules/components/fileDetailTab/Index.vue';
import CustomerDetail from '@/modules/mdm/views/contractModules/components/customerDetail/Index.vue';
import CompanyDetail from '@/modules/mdm/views/contractModules/components/companyDetail/Index.vue';
import PrescreenDetail from '@/modules/mdm/views/contractModules/components/prescreenDetail/Index.vue';
import OilDetail from '@/modules/mdm/views/contractModules/components/oilDetail/Index.vue';
import RateDetail from '@/modules/mdm/views/contractModules/components/rateDetail/Index.vue';
// import priceDetail from '@/modules/mdm/views/contractModules/components/priceDetail/Index.vue';
import IflowDetail from '@/modules/mdm/views/contractModules/components/iflowDetail/Index.vue';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { collapse } from './collapseInfosConfig.js';
import { STATE_NEW, STATE_MAIN, STATE_OTHER_CHANGE, STATE_REPLENISH, STATE_STOP } from '@/modules/mdm/constant/contractManage.js';
import { contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import { groupInfoAdd, groupInfoGetById, groupInfoCreateFlowApi, groupInfoSubmitApi } from '@mdm/api/contractManage/groupDetailApi.js';
import { groupInfoRemove } from '@mdm/api/contractManage/contractGroupListApi.js';
import _ from 'lodash';
import { config, isOtherChangeShow, isOtherChangeHide, actions } from './config.js';
import { MessageBox } from 'element-ui';
// import bigDataLog from '@/utils/bigDataLog.js';
import contractList from '@/modules/mdm/views/contractModules/contractGroupManage/detail/contractList/Index.vue';
import utils from '@/components/utils/common.js';
const { MAGIC_NUMBER } = utils;
export default {
    name: '',
    components: {
        lotsButton,
        CollapseInfos,
        FileDetail,
        CustomerDetail,
        CompanyDetail,
        PrescreenDetail,
        OilDetail,
        RateDetail,
        // priceDetail,
        IflowDetail,
        contractList
    },
    props: {
        code: { // 页面识别码（可能是contractGroupCode 或者 contractChangeCode: 当页面是其他变更并且有变更编号时）
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            detailLoading: false,
            deleteHeaderLoading: false,
            priceLoading: false,
            activeName: 'filesDetail',
            collapse: _.cloneDeep(collapse),
            detailDataLocal: _.cloneDeep(this.formatData(this.detailData)),
            authorityCode: btnAuthority.contractGroupManage,
            applyIflowLoading: false,
            isHandleEdit: false,
            viewFlowUrl: '',
            viewFlowLoading: false,
            iflowList: [],
            fdId: '',
            submitFWLoading: false,
            viewPriceSub: false,
            editList: false,
            actions
        };
    },
    watch: {
        detailData: {
            handler(val) {
                this.refleshPage();
            },
            deep: true
        }
    },
    computed: {
        disableBus() {
            const disableBus = {
                addNew: false, // 新增
                saveHeader: !this.saveHeaderFlag, // 保存
                submitIFlow: !(['0', '1'].includes(this.detailDataLocal.contractGroupStatus + '') && ![STATE_NEW].includes(this.detailPageState)), // 提交审批
                delHeader: !this.delHeaderFlag // 删除
            };
            return disableBus;
        },
        saveHeaderFlag() {
            return ['0', '1'].includes(this.detailDataLocal.contractGroupStatus + '');
        },
        delHeaderFlag() {
            return ['0'].includes(this.detailDataLocal.contractGroupStatus + '');
        },
        // eslint-disable-next-line complexity
        subTabShow() {
            return {
                fileDetail: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) // 其他变更：基础信息变更不展示
                    : ![STATE_NEW].includes(this.detailPageState), // 新增：不展示
                coustomer: this.detailPageState === STATE_OTHER_CHANGE ? true // 其他变更:展示
                    : ![STATE_NEW].includes(this.detailPageState), // 新增：不展示
                company: ![STATE_NEW].includes(this.detailPageState),
                prescreen: this.detailPageState === STATE_OTHER_CHANGE ? true // 其他变更:展示
                    : ![STATE_NEW].includes(this.detailPageState) && String(this.detailDataLocal.contractCharacter) !== '4', // 新签、预签不展示
                relationDetail: [STATE_MAIN].includes(this.detailPageState),
                oilDetail: this.detailPageState === STATE_OTHER_CHANGE ? String(this.detailDataLocal.oilLinkedProportion) !== '0' // 其他变更: 油价联动为否不展示
                    : String(this.detailDataLocal.oilLinkedProportion) !== '0' && ![STATE_NEW].includes(this.detailPageState), // 新签、油价联动为否不展示
                rate: ![STATE_OTHER_CHANGE, STATE_NEW].includes(this.detailPageState),
                otherChange: ![STATE_OTHER_CHANGE, STATE_NEW].includes(this.detailPageState),
                iflow: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) : ![STATE_NEW].includes(this.detailPageState),
                warehouseDetail: false,
                price: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) : ![STATE_NEW].includes(this.detailPageState)
            };
        },
        // eslint-disable-next-line
        COLLAPSE() { // 配置项动态计算
            const {
                contractCharacter, stampMode, oilLinkedProportion, freightFloatMode, settlementMode, ifWatermarking,
                paymentCollection, oilIsGroupCustomer, businessType, accountEntityName, contractSource, changeType
            } = this.detailDataLocal;
            // eslint-disable-next-line max-lines-per-function
            this.collapse.forEach((v, i) => {
                // eslint-disable-next-line complexity, max-lines-per-function
                v.formList.forEach((v2, i2) => {
                    // 基础信息
                    if (isOtherChangeShow.includes(v2.prop)) {
                        v2.visible = this.detailPageState === STATE_OTHER_CHANGE;
                    }
                    if (isOtherChangeHide.includes(v2.prop)) {
                        v2.visible = this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    // if (v2.prop === 'contractCharacter') { // 新增合同，合同性质可编辑
                    //     v2['disabled'] = this.detailPageState !== STATE_NEW;
                    // }
                    if (v2.prop === 'mainContract') { // 主合同编号: 固定禁用；合同性质为续签、补充、终止是展示;
                        // v2.rule[0].required = ['2'].includes(String(contractCharacter));
                        v2.visible = ['2', '3', '5'].includes(String(contractCharacter));
                    }
                    if (v2.prop === 'accountEntityName') { // 补充/终止协议/其他变更，禁用会计主体
                        v2.disabled = [STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE].includes(this.detailPageState) || (this.detailPageState === STATE_NEW && String(contractCharacter) === '3');
                        v2.type = v2.disabled ? 'custom' : 'advance';
                    }
                    if (['validTime', 'failTime'].includes(v2.prop) && this.detailPageState === STATE_OTHER_CHANGE) {
                        // 当为其他变更时，合同性质为预签4, 且基础变更时，生效日期、失效日期可编辑, 或变更类型为日期变更时可编辑，其他情况只读
                        v2.disabled = String(changeType) === '0' ? String(contractCharacter) !== '4' : String(changeType) !== '2';
                        v2.type = v2.disabled ? 'custom' : 'time';
                    }
                    if (v2.prop === 'projectCode') {
                        v2.rule[0].required = String(oilIsGroupCustomer) === '1' && ![STATE_OTHER_CHANGE, STATE_STOP].includes(this.detailPageState); // 项目编码：内外部业务为外部1 且为 非变更合同/终止协议 时，必填;
                        // CRM 且 新签、续签、预签、非变更可编辑
                        v2.disabled = !(['1', '2', '4'].includes(String(contractCharacter)) && this.detailPageState !== STATE_OTHER_CHANGE && String(contractSource) === '1');
                        v2.type = v2.disabled ? 'custom' : 'advance';
                    }
                    if (v2.prop === 'customerType') { // 仓储运作类型：业务类别包含‘仓储’才展示
                        const businessTypeList = businessType || [];
                        let hasWarehouse = false;
                        if (businessTypeList.length) {
                            businessTypeList.forEach(item => {
                                if (item === '1') hasWarehouse = true;
                            });
                        }
                        v2.visible = hasWarehouse;
                    }
                    if (v2.prop === 'priceType') { // 价格情况：合同性质不为预签 且不为其他变更才展示
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'stampMode') { // 盖章方式：合同性质不为预签 且不为其他变更才展示
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'sealCategory') { // 印章类型：会计主体有数据，才可编辑; 合同性质不为预签，不为其他变更，盖章方式不为电子 才展示
                        v2.disabled = !accountEntityName;
                        v2.type = v2.disabled ? 'custom' : 'select';
                        v2.placeholder = accountEntityName ? '请选择' : '请先选择会计主体';
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(stampMode) !== '1';
                    }
                    if (v2.prop === 'sealAdminAccount') { // 印章管理员展示：合同性质不为预签，不为其他变更，盖章方式不为电子
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(stampMode) !== '1';
                    }
                    if (v2.prop === 'ifWatermarking') { // 是否生成水印: 合同性质不为预签，不为其他变更
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'noWatermarkingReason') { // 不生成水印原因: 合同性质不为预签，不为其他变更, 并且是否生成水印为否
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(ifWatermarking) === '0';
                    }
                    if (v2.prop === 'isCloudWh') { // 是否云仓: 当内外部业务为内部0时必填，外部时不必填且隐藏
                        v2.visible = String(oilIsGroupCustomer) === '0';
                    }
                    if (v2.prop === 'professionalCompany') { // 专业公司: 当合同来源为非CRM时只读, 内外部业务为内部0 时隐藏
                        v2.disabled = String(contractSource) !== '1';
                        v2.type = v2.disabled ? 'custom' : 'select';
                        v2.visible = String(oilIsGroupCustomer) !== '0';
                    }
                    if (v2.prop === 'productType') { // 产品类型：来源系统非CRM只读
                        v2.type = v2.disabled ? 'custom' : 'select';
                    }
                    if (v2.prop === 'businessType') { // 业务类别：日期变更，价格变更只读
                        v2.disabled = this.detailPageState === STATE_OTHER_CHANGE && ['1', '2'].includes(String(changeType));
                        v2.type = v2.disabled ? 'custom' : 'select';
                    }
                    // 财务信息
                    const NotCRM = [ 'contractAccountOf', 'grossMarginPlan', 'operatGrossMarginPlan', 'deposit', 'settlementMode', 'acceptanceRate', 'acceptanceDays' ];
                    if (NotCRM.includes(v2.prop)) { // 合同全账期中标采购毛利率/中标项目经营中心利润率/押金/结算方式/承兑比例/承兑期限：来源系统非CRM只读
                        v2.disabled = String(contractSource) !== '1';
                        v2.type = v2.disabled ? 'custom' : (v2.prop === 'settlementMode' ? 'select' : 'input');
                    }
                    if (v2.prop === 'expectedTrafficPlan') { // 预计业务量：来源系统非CRM 且不为终止协议，只读
                        v2.disabled = String(contractSource) !== '1' && String(contractCharacter) !== '5';
                        v2.type = v2.disabled ? 'custom' : 'input';
                    }
                    if (v2.prop === 'acceptanceRate') { // 承兑比例：结算方式选择 现汇+承兑3 或承兑2 时，必填; 来源系统非CRM只读
                        v2.rule[0].required = ['2', '3'].includes(String(settlementMode));
                    }
                    if (v2.prop === 'acceptanceDays') { // 承兑期限: 现汇+承兑3 或承兑2 时，必填; 来源系统非CRM只读
                        v2.rule[0].required = ['2', '3'].includes(String(settlementMode));
                    }
                    if (['remittanceDate', 'receiveTo', 'feeRate', 'feePaymentMode', 'minFeeAmount'].includes(v2.prop)) { // 汇款时间/收款至/服务费率(%)/服务费支付方式: 是否代收货款 为 是时必填
                        v2.rule[0].required = String(paymentCollection) === '1';
                    }
                    if (v2.prop === 'remittanceDate') { // 汇款时间:
                        v2.visible = v2.rule[0].required;
                    }
                    if (v2.prop === 'minFeeAmount') { // 最低服务费:
                        v2.visible = v2.rule[0].required;
                    }
                    // 油价信息
                    if (v2.prop === 'oilLinkedProportion') { // 预签和终止协议禁用油价联动，默认为否;
                        v2.disabled = ['4', '5'].includes(String(contractCharacter));
                        v2.type = v2.disabled ? 'custom' : 'select';
                    }
                    const isShowoilLinkedArr = [ // 是否油价联动字段直接控制 显示/隐藏的字段
                        'linkageType', 'triggerOilPriceFloat', 'freightFloatMode', 'oilLinkageExecutime',
                        'newOilSelectionMethod', 'triggerPriceAgain', 'conditionsDetail'
                    ];
                    if (isShowoilLinkedArr.includes(v2.prop)) {
                        v2.visible = String(oilLinkedProportion) !== '0';
                    }
                    if (v2.prop === 'freightLinkedScale') { // 运价联动比例(%)： 运价浮动方式 为 联动比例2 展示
                        v2.visible = String(freightFloatMode) === '2' && String(oilLinkedProportion) !== '0';
                    }
                    if (v2.prop === 'freightFloatCoefficient') { // 运价浮动系数(%)： 运价浮动方式 为 浮动系数1 展示
                        v2.visible = String(freightFloatMode) === '1' && String(oilLinkedProportion) !== '0';
                    }
                    // 增量10.21版本: 其他变更：日期变更2/价格变更1其余字段只读字段
                    if (['1', '2'].includes(String(changeType))) {
                        if (!['validTime', 'failTime', 'changeReason', 'changeDetail'].includes(v2.prop)) {
                            v2.disabled = true;
                            v2.type = 'custom';
                        }
                        if (v2.rule) {
                            v2.rule[0].required = !v2.disabled;
                        }
                    }
                });
            });
            return this.collapse;
        }
    },
    mounted() {
        // bigDataLog.sendDetailMessageByKey('bigData-page-detailPage');
        this.refleshPage();
        if (this.detailPageState === STATE_NEW) {
            this.activeName = 'companyDetail';
        }
    },
    methods: {
        formatData(data) { // 服务类型字符串数组转换函数
            if (data.businessType && typeof data.businessType === 'string') {
                data.businessType = data.businessType.split(',') || [];
            }
            return data;
        },
        async refleshPage() {
            this.isHandleEdit = false;
            if (this.detailData.id) {
                // 请求详情数据
                this.detailLoading = true;
                groupInfoGetById({ id: this.detailData.id }).then(res => {
                    if (+res.code === 0) {
                        this.dataInit(res.data);
                    }
                }).finally(() => {
                    this.detailLoading = false;
                });
            } else {
                await this.dataInit(this.detailData);
            }
        },
        dataInit(data) {
            const newData = this.formatData(data);
            if ([STATE_REPLENISH, STATE_STOP].includes(this.detailPageState)) {
                this.activeName = 'customerDetail';
            }
            if (['4', '5'].includes(String(newData.contractCharacter))) { // 合同性指为预签/终止协议，油价联动固定为否
                newData['oilLinkedProportion'] = '0';
            }
            this.handleInfosChange({ form: newData || {} });
        },
        handleInfosChange(formObjData = {}) { // 表单组件编辑回调，更新本地数据
            this.detailDataLocal = _.cloneDeep({ ...formObjData.form, contractStatus: undefined, contractCharacter: undefined, contractCode: undefined });
            const { itemSetting } = formObjData;
            if (itemSetting) { this.isHandleEdit = true; } // 监控表单是否编辑过
            if (!itemSetting) {
                this.detailDataLocal.contractGroupCode && this.$refs.contractListRef.getDataList(); // 刷新合同明细列表
                this.$refs.upholdHealder.refleshPage(this.detailDataLocal); // 刷新表单子组件
            }
        },
        saveDataFormat (data) { // 保存的数据格式转化函数
            const integerKey = config.formatProp;
            // 整形、浮点型 格式参数转化
            integerKey.forEach(item => {
                if (data[item] || String(data[item]) === '0') {
                    data[item] = Number(data[item]);
                } else {
                    data[item] = null;
                }
            });
            data.businessType = null; // 合同组没有业务类型字段（保存在合同明细列表）
            this.COLLAPSE.forEach((v, i) => {
                v.formList.forEach((v2, i2) => {
                    if (!v2.visible && v2.prop !== 'contractSource') {
                        data[v2.prop] = null; // 清空隐藏的字段值
                    }
                });
            });
            return data;
        },
        // eslint-disable-next-line complexity, max-lines-per-function
        async saveHeader() {
            // 主合同保存校验
            const data = _.cloneDeep(this.$refs.upholdHealder.onsave());
            if (!data) return;
            if (this.detailPageState === STATE_NEW) { // 新增合同保存时
                // 分公司明细检验及处理
                const contractListData = _.cloneDeep(this.$refs.contractListRef.subTabTable.totalData);
                let notfillCangChu = false;
                if (contractListData.length) {
                    contractListData.forEach((item) => {
                        item.backupBusinessType = _.cloneDeep(item.businessType);
                        (item.backupBusinessType.some(key => key === '1') && !item.customerType) && (notfillCangChu = true);
                        item.businessType = item.businessType.length ? item.businessType.join(',') : ''; // 业务类别 转成字符串
                        // 非油价联动，删除油价详细条款说明
                        +this.detailDataLocal.oilLinkedProportion !== 1 && (Reflect.deleteProperty(item, 'conditionsDetail'));
                    });
                }
                const stopFlag = contractListData.some((item) => (!item.contractName || !item.accountEntityName || !item.accountEntityId
                    || !item.validTime || !item.failTime || !item.businessType
                    || (item.backupBusinessType.some(key => key === '1') && !item.customerType) // 业务类别包含仓储，仓储运作类型不能为空
                    || (+this.detailDataLocal.oilLinkedProportion === 1 && !item.conditionsDetail))); // 油价联动，油价详细条款说明不能为空
                if (!contractListData.length || stopFlag) {
                    const element = document.getElementById('pane-0000new');
                    element.scrollTo(0, MAGIC_NUMBER.ONE_THOUSAND_AND_ONE_HUNDRED); // 页面滚动到底部
                    this.$message({
                        message: !contractListData.length ? '合同明细列表不能为空' : (notfillCangChu ? '业务类别包含仓储时，仓储运作类型必填' : '合同明细列表必填字段不能为空'),
                        type: 'warning'
                    });
                    return false;
                }
                const contractList = contractListData.map(item => {
                    return {
                        contractName: item.contractName,
                        accountEntityName: item.accountEntityName,
                        accountEntityId: item.accountEntityId,
                        validTime: item.validTime,
                        failTime: item.failTime,
                        businessType: item.businessType,
                        customerType: item.customerType,
                        conditionsDetail: item.conditionsDetail
                    };
                });
                data.contractList = contractList;
            }
            const formDataObj = this.saveDataFormat(data); // 参数格式转化
            this.detailLoading = true;
            groupInfoAdd(formDataObj).then(resSave => {
                if (+resSave.code === 0) {
                    this.$message.success('保存成功');
                    if (this.detailPageState === STATE_NEW) { // 新增保存，更新页面所有信息
                        groupInfoGetById({ id: resSave.data.id }).then(res => {
                            this.$emit('save-data', res.data, this.detailPageState);
                        });
                    } else { // 非新增保存，在当前页刷新数据
                        this.activeName = 'filesDetail';
                        this.refleshPage();
                    }
                }
            }).finally(() => {
                this.detailLoading = false;
            });
        },
        addNew() {
            this.$emit('add-new');
        },
        refreshAll() {
            const data = JSON.parse(JSON.stringify(this.detailDataLocal));
            this.$emit('refrash-data', data);
        },
        stopLoading() {
            this.detailLoading = false;
        },
        handleClickTab(data) {
            if (data.name === 'priceDetail') {
                this.viewPriceSub = true;
            } else {
                this.viewPriceSub = false;
            }
            const editTabArr = [ 'filesDetail', 'customerDetail', 'companyDetail',
                'prescreenDetail', 'oilDetail', 'rateDetail' ];
            const readonlyTabArr = ['relationDetail', 'otherChangeDetail', 'iflowDetail'];
            if (editTabArr.includes(data.name)) { // 解决切换编辑表格时操作列错位问题
                this.$nextTick(() => {
                    if (this.$refs[`${data.name}Ref`]) {
                        const tableRef = this.$refs[`${data.name}Ref`].$refs.editTableBox;
                        if (tableRef) {
                            tableRef.$refs.moduleTable.doLayout();
                        }
                    }
                });
            }
            if (readonlyTabArr.includes(data.name)) { // 数纯展示表格，刷新数据（只刷新激活状态的）
                this.$refs[`${data.name}Ref`].getDataFn();
            }
        },
        applyIflow() { // 提交审批
            this.applyIflowFn(this.detailDataLocal.contractGroupCode, 'applyIflowLoading');
        },
        async applyIflowFn(code = this.detailDataLocal.contractGroupCode, loadingKey) { // 提交审批
            if (this.isHandleEdit) {
                return this.$message.warning('数据可能有改动，提交审批前请先保存数据');
            }
            const passFlag = this.$refs.upholdHealder.onsave();
            if (!passFlag) return;
            const { origin, pathname } = window.location;
            // eslint-disable-next-line max-len
            const url = `${origin}${pathname}#/mipiframe?contractCode=${code}&fdTemplateKey=CRM_contract_group&group=1`;
            const params = {
                id: this.detailDataLocal.id,
                sourceSystemUrl: url
            };
            this[loadingKey] = true;
            const res = await groupInfoCreateFlowApi(params);
            this[loadingKey] = false;
            if (res && +res.code === 0) {
                this.$message({
                    message: '提交审批流程成功！稍后可到详情页-流程信息 查看或审批流程',
                    type: 'success'
                });
                await this.refleshPage();
                // this.activeName = 'iflowDetail';
            }
        },
        deleteHeader() {
            MessageBox.confirm('确定要删除该合同吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.deleteHeaderLoading = true;
                const res = await groupInfoRemove({ id: this.detailDataLocal.id, contractGroupCode: this.detailDataLocal.contractGroupCode });
                this.deleteHeaderLoading = false;
                if (+res.code === 0) {
                    this.$message.success('删除成功');
                    this.$emit('close-page', this.code + this.detailPageState);
                }
            }).catch(res => {});
        },
        handleClickMoreInfo(data) { // 解决点击折叠/展开“合同明细”折叠筐，子页签编辑错位问题
            if (data.includes('moreInfo')) {
                this.handleClickTab({ name: this.activeName });
            }
        },
        // 获取最新iflow流程子页签流程状态数据函数：
        async getNewIflowList(code) {
            return contractWorkflowList({
                contractCode: code,
                pageSize: 10,
                pageNo: 1
            }).then(res => {
                if (+res.code === 0) {
                    if (res.data && res.data.list && res.data.list.length) {
                        // 保存流程状态数据
                        this.fdId = res.data.list[0].mipId;
                        this.iflowList = res.data.list;
                    }
                    return res;
                }
            });
        },
        // eslint-disable-next-line complexity
        async handleViewFlow() {
            const { contractGroupCode } = this.detailDataLocal;
            const code = contractGroupCode;
            if (!this.viewFlowUrl) {
                // 请求流程信息
                this.viewFlowLoading = true;
                const res = await this.getNewIflowList(code);
                this.viewFlowLoading = false;
                if (+res.code === 0 && res.data && res.data.list && res.data.list.length) {
                    this.viewFlowUrl = res.data.list[0].mipUrl;
                }
            }
            if (this.viewFlowUrl) {
                const query = { fdId: this.fdId, fdTemplateKey: 'CRM_contract_group', contractCode: code, group: 1 };
                const routeData = this.$router.resolve({ name: 'mipIframe', query });
                window.open(routeData.href, '_blank');
            } else {
                this.$message.warning('未查询到流程信息');
            }
        },
        handleAddRow() {
            this.$refs.contractListRef.addRow();
            this.editList = true;
        },
        handleSaveRows() {
            // 123
        },
        handleSubmitFW() {
            this.submitFWLoading = true;
            groupInfoSubmitApi({ contractGroupCode: this.detailDataLocal.contractGroupCode }).then(res => {
                if (+res.code === 0) {
                    this.$message.success('操作成功');
                    setTimeout(() => {
                        this.$refs.contractListRef.getDataList();
                    }, MAGIC_NUMBER.SIX_HUNDRED);
                }
            }).finally(() => (this.submitFWLoading = false));
        },
        handleCurrentMain(current) {
            if (current && current.id && JSON.stringify(current) !== '{}') {
                const { contractCode, contractCharacter, contractStatus } = current;
                this.detailDataLocal.contractCode = contractCode;
                this.detailDataLocal.contractCharacter = contractCharacter;
                this.detailDataLocal.contractStatus = contractStatus;
                // 点击合同明细，价格表非激活时，恢复明细正常宽度
                if (this.activeName !== 'priceDetail') {
                    this.viewPriceSub = false;
                }
            } else {
                if (current === null) {
                    this.editList = false;
                }
                this.detailDataLocal.contractCode = null;
                this.detailDataLocal.contractCharacter = null;
                this.detailDataLocal.contractStatus = null;
            }
        },
        handleEdit() {
            this.editList = true;
        },
        handleCancel(list) {
            this.editList = list.some(item => item.edit === 2);
        }
    }
};
</script>

<style lang = "less">
.contract-detail {
    .detail-toolBar {
        .contract-detail-btn {
            margin-right: 10px;
        }
        .item-flow-right {
            float: right;
            margin-left: 10px;
        }
  }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }
    .el-tabs__header {
        margin-bottom: 0px;
    }
    .el-tabs__content {
        .file-detail-btn {
            background-color: #fffbf2;
        }
        .file-detail-btn.list-btn {
            background-color: #fff;
            margin-bottom: 4px;
            .el-button {
                padding: 6px 7px 6px 5px;
            }
        }
        .footer {
            padding: 10px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .detail-more-collapse.el-collapse {
        border-top: none;
        border-bottom: none;
        .el-collapse-item__content {
            padding-bottom: 0;
            .contract-list-detail {
                // 123
                display: flex;
                .contract-list-detail-list {
                    &.NEW {
                        width: 100%;
                    }
                    &.view-price {
                        width: 18%;
                    }
                    &.edit-list {
                        width: 72%;
                    }
                    width: 40%;
                    padding-right: 5px;
                    box-sizing: border-box;
                    .list-btn {
                        margin-top: 7px;
                        width: 100%
                    }
                    .list-father {
                        width: 100%;
                        .list-content {
                            // background-color: #cfc;
                        }
                    }
                }
                .contract-list-detail-sub {
                    &.NEW {
                        width: 0;
                    }
                    &.view-price {
                        width: 82%;
                    }
                    &.edit-list {
                        width: 28%;
                    }
                    width: 60%;
                    .sub-btn {
                        width: 100%
                    }
                    .sub-tab-father {
                        width: 100%;
                        // overflow-y: auto;
                        .sub-tab-content {
                            width: 1600px;
                        }
                    }
                }
            }
        }
    }
    .detail-toolBar {
        .el-loading-spinner {
            top: 0;
            margin-top: 0;
            height: 100%;
            .circular {
                height: 26px;
                width: 26px;
            }
        }
    }
}
</style>
