<!-- eslint-disable max-lines -->
 <template>
    <div class="file-detail-subtab">
        <div class="file-detail-btn" v-if="!readonly">
            <lots-button
                v-has="authorityCode.subAddNewFile"
                type="text"
                icon="el-icon-circle-plus-outline"
                size="medium"
                @click="addNew"
                :loading="addNewLoading"
                :disabled="isDisableAddBtn">新增
            </lots-button>
            <lots-button
                v-has="authorityCode.subAddNewFileBatch"
                v-if="detailData.contractGroupCode && $route.name === 'contractGroupManage'"
                type="text" icon="el-icon-refresh" size="medium" @click="handleSyncData" :disabled="isDisableAddBtn">批量更新附件</lots-button>
        </div>
        <div class="file-detail-table">
            <edit-table-box
                ref="editTableBox"
                v-loading="subTabTable.tableLoading"
                :selection="config.selection"
                :height="tableHeight"
                :index="config.index"
                :columns="config.columns"
                :tableData="subTabTable.totalData"
                :actions="actions"
                :editDisableRule="(row) => { return !!row.id}"
                @btn-event="actionClick" />
            <div class="footer">
                <lots-pagination
                    @size-change="subTabTable.sizeChange"
                    :current-page.sync="subTabTable.pageNo"
                    @current-change="subTabTable.pageChange"
                    size="small"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="subTabTable.pageTotal" />
            </div>
            <!-- 新增弹窗 -->
            <el-dialog
                @close="closeLog"
                title="附件新增"
                :visible.sync="addDialog"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                width="470px">
                <el-form
                    :model="filesForm"
                    label-position="right"
                    class="form-dialog"
                    label-width="80px"
                    :rules="staticConfig.formConfig.rules"
                    ref="filesFormDialog">
                    <el-form-item label="附件名称" prop="accessoryName">
                        <el-input v-model="filesForm.accessoryName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="文件类型" prop="fileType">
                        <lots-select
                            optionsKey="SYS_CR_CONTRACT_FILE_TYPE"
                            v-model="filesForm.fileType"
                            @change="fileTypeChange"
                            @clear="filesTypeClear"
                            clearable
                            style="width: 100%" />
                    </el-form-item>
                    <!-- <el-form-item
                        v-if="hasFilesType && filesForm.fileType === 'ContractNeedStamp'"
                        label="选择模板"
                        prop="templateName"
                    >
                        <otpAdvance
                        @getAdvanceValue="getAdvanceValue"
                        :mValue="filesForm.templateName"
                        :params="staticConfig.formConfig.templateAdvance"
                        clearable
                        :disabled="false"
                        >
                        </otpAdvance>
                    </el-form-item> -->
                </el-form>
                <template v-slot:footer class="dialog-footer">
                    <lots-button size="mini" @click="closeLog">取 消</lots-button>
                    <lots-button
                        size="mini"
                        type="primary"
                        @click="upload('filesFormDialog')"
                        :disabled="uploadOrHangdleForm">上传附件</lots-button>
                    <!-- <lots-button
                        size="mini"
                        type="primary"
                        v-loading="btnLoading"
                        :disabled="!uploadOrHangdleForm"
                        @click="handleSave('filesFormDialog')"
                        >生成合同</lots-button
                    > -->
                </template>
            </el-dialog>
            <dialog-import-file
                ref="Import"
                class="contract-file-import-dialog"
                :title="'上传附件'"
                :ImportConfig="staticConfig.dialogImportConfig.ImportConfig"
                :showDownLoad="false"
                @uploadClose="uploadClose">
            </dialog-import-file>
        </div>
        <update-batch-dialog
            v-if="updateBtchVisible"
            :FilesDetailClass="FilesDetailClass"
            :detailData="detailData"
            :detailPageState="detailPageState"
            :readonly="readonly"
            @confirm="updateConfirmCb"
            @handleClose="updateBtchVisible = false">
        </update-batch-dialog>
        <!-- <update-contract-dialog
            v-if="updateContractVisible"
            :detailData="detailData"
            :selectedFiles="selectedFiles"
            :detailPageState="detailPageState"
            @confirm="updateContractConfirmCb"
            @handleClose="updateContractVisible = false">
        </update-contract-dialog> -->
        <advance-multi-dialog
            ref="advanceMultiDialog"
            @confirm="detaliSearchSure">
            <template #head>
                <div class="head-tips">
                请选择需要批量更新的合同：
                </div>
            </template>
        </advance-multi-dialog>
        <advanceDialog ref="advanceDialogRef"  @confirm="contractTempSearchSure" />
    </div>
</template>

<script>
import { ref, reactive, computed, watch, onMounted, onActivated } from '@vue/composition-api';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import DialogImportFile from '@/components/lots/DialogImportFile/Index';
import advanceMultiDialog from '@/components/lots/otpAdvanceMultiDialog';
import advanceDialog from '@/components/lots/otpAdvanceDialog/index';
import lotsButton from '@/components/lots/lotsButton';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import {
    contractFilesList,
    contractFilesDownload,
    contractFilesView,
    contractFilesDelete,
    contractFilesPrefillAddApi,
    getCdTsCustomerProjectsApi,
    createJDSKFile
} from '@mdm/api/contractManage/contractFootPageApi.js';
import { enclosureInfoUpdateBatch } from '@mdm/api/contractManage/contractGroupFootApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { importConfig, tableConfig, contractAdvanceParams, contractTempParams } from './config.js';
import { STATE_NEW, STATE_OTHER_CHANGE } from '@/modules/mdm/constant/contractManage.js';
import _ from 'lodash';
import { Message, MessageBox } from 'element-ui';
import store from '@/store/index';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import utils from '@/components/utils/common.js';
import updateBatchDialog from './updateBatchDialog/Index';
// import updateContractDialog from './updateContractDialog/Index';
const contractSourceForJDSK = '6'; // 合同来源京东数科
const { MAGIC_NUMBER } = utils;
export default {
    name: 'FileDetail',
    components: {
        // tableBox,
        editTableBox,
        lotsPagination,
        lotsSelect,
        // otpAdvance,
        DialogImportFile,
        lotsButton,
        updateBatchDialog,
        // updateContractDialog,
        advanceMultiDialog,
        advanceDialog
    },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const btnPermissionsArr = store.getters.btnPermissions;
        const config = reactive(_.cloneDeep(tableConfig));
        const staticConfig = reactive(importConfig);
        const { user } = setupContext.root.$store.getters;
        const isDisableAddBtn = ref(false);
        const addDataArr = ref([]);
        const actions = reactive({
            fixedWidth: 200,
            list: [
                {
                    label: '上传',
                    event: 'upload',
                    displayRule: (row) => {
                        return !row.id && btnPermissionsArr.includes(authorityCode.subFileUpLoad) && !props.readonly;
                    }
                },
                {
                    label: '下载',
                    event: 'download',
                    displayRule: (row) => {
                        return props.detailPageState === STATE_OTHER_CHANGE ? +props.detailData.changeType !== 2 && !props.readonly
                            : !!row.id && btnPermissionsArr.includes(authorityCode.subFileDownLoad) && !props.readonly;
                    }
                },
                {
                    label: '预览',
                    event: 'viewFile',
                    displayRule: (row) => {
                        return !!row.id && +props.detailData.contractCharacter !== MAGIC_NUMBER.FOUR && btnPermissionsArr.includes(authorityCode.subFileView) && !props.readonly;
                    }
                },
                {
                    label: '删除',
                    event: 'del',
                    displayRule: (row) => {
                        return !!row.id && !isDisableAddBtn.value && btnPermissionsArr.includes(authorityCode.subDeleteFile) && !props.readonly;
                    }
                }
            ]
        });
        const isAfterUpload = ref(false);
        class FilesDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.detailData.contractCode;
                return condition;
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                    isAfterUpload.value && addNew(); // 上传文件后的列表刷新自动预填信息
                }
                isAfterUpload.value = false;
            };
            afterDeleteRow(res, successMsg) {
                if (+res.code === 0) {
                    Message.success(successMsg);
                    this.getList(); // 刷新列表
                } else {
                    this.getList(); // 刷新列表,报错也要刷新页面
                }
            }
        }
        const subTabTable = new FilesDetailClass({
            Api: {
                listApi: contractFilesList,
                deleteApi: contractFilesDelete
            }
        });
        const filesBasicParams = reactive({
            contractCode: '',
            userCode: '',
            contractCharacter: ''
        });
        const addDialog = ref(false);
        const hasFilesType = computed(() => !!filesForm.value.fileType);
        const uploadOrHangdleForm = computed(
            () => !!(hasFilesType && filesForm.value.templateName)
        );
        const filesForm = ref({
            accessoryName: '',
            fileType: '',
            templateName: '',
            draftModel: ''
        });

        const btnLoading = ref(false);
        const addDataList = computed(() => {
            const arrName = ['销售合同', '净利分析模型', '业务审批合作表', '合同终止附件', '产品折扣表'];
            const initArr = [
                { accessoryName: '销售合同', edit: 2 },
                { accessoryName: '净利分析模型', edit: 2 },
                { accessoryName: '业务审批合作表', edit: 2 },
                { accessoryName: '合同终止附件', edit: 2 },
                { accessoryName: '产品折扣表', edit: 2 }
            ];
            subTabTable.totalData.value.forEach(item => {
                if (arrName.includes(item.accessoryName)) {
                    const index = initArr.findIndex((sub) => {
                        return sub.accessoryName === item.accessoryName;
                    });
                    if (index !== -1) { initArr.splice(index, 1); }
                }
            });
            const initFirstJDSKIndex = initArr.findIndex(it => it.accessoryName === '销售合同');
            if (initFirstJDSKIndex > -1 && +props.detailData.contractSource === +contractSourceForJDSK) {
                initArr[initFirstJDSKIndex].fileType = 'ContractNeedStamp'; // 主合同文件
            }
            return initArr;
        });
        const addNewLoading = ref(false);
        const addNew = async () => {
            await getFileTypeDict();
            const { contractCode, projectCode, projectType } = props.detailData;
            const theProjectType = projectTypeLocal.value || projectType || '';
            if (projectCode && ['1', '2'].includes(String(theProjectType))) {
                addNewLoading.value = true;
                contractFilesPrefillAddApi({
                    contractCode: contractCode,
                    projectType: projectTypeLocal.value || projectType || ''
                }).then(res => {
                    if (+res.code === 0) {
                        if (+res.data.success === 1) {
                            // 预填充有新插入数据，刷新列表
                            Message.success('附件导入成功');
                            subTabTable.getList();
                        } else {
                            // 预填充没有新插入数据，走原逻辑
                            autoAdd();
                        }
                    } else {
                        // 预填接口报错，走原逻辑
                        autoAdd();
                    }
                }).finally(() => {
                    addNewLoading.value = false;
                });
            } else {
                autoAdd();
            }
            function autoAdd() {
                if (addDataList.value.length) {
                    subTabTable.totalData.value.push(...addDataList.value);
                } else {
                    subTabTable.totalData.value.push({ accessoryName: '', edit: 2 });
                }
            };
        };
        const tableIndex = ref(0);
        // 对表格做一些操作
        // eslint-disable-next-line complexity
        const actionClick = (selection, event, index) => {
            const { fileId, id, attachmentId, contractName, internalSealFileUrl } = selection;
            const params = {
                fileId,
                id,
                attachmentId,
                userCode: user.userCode,
                contractName,
                contractCharacter: props.detailData.contractCharacter + ''
            };
            tableIndex.value = index;
            switch (event) {
            case 'upload':
                if (!selection.accessoryName) {
                    Message.warning('请输入附件名称');
                    return;
                }
                if (!selection.fileType) {
                    Message.warning('请选择文件类型');
                    return;
                }
                filesForm.value.accessoryName = selection.accessoryName;
                filesForm.value.fileType = selection.fileType;
                // addDialog.value = true;
                if (+props.detailData.contractSource === +contractSourceForJDSK && selection.fileType === 'ContractNeedStamp') {
                    openContractTemplate();
                    return;
                }
                upload();
                break;
            case 'del':
                subTabTable.deleteRow(params, false);
                break;
            case 'download':
                if (props.detailData.contractCharacter + '' === '4') { // 预签直接连接下载
                    if (!internalSealFileUrl) {
                        return Message.warning('文件丢失');
                    }
                    const elink = document.createElement('a');
                    elink.style.display = 'none';
                    elink.href = internalSealFileUrl;
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                } else {
                    download(params);
                }
                break;
            case 'viewFile':
                viewDoc(params);
                break;
            default:
                break;
            }
        };
        const openContractTemplate = () => {
            setupContext.refs.advanceDialogRef.show(true, contractTempParams, {}, contractTempCode.value.JDSK);
        };
        // 附件下载
        const download = (row) => {
            const params = {
                userCode: row.userCode,
                attachmentId: row.attachmentId,
                fileId: row.fileId,
                contractName: row.contractName
            };
            contractFilesDownload(params);
        };
        // 附件预览
        const viewDoc = (params) => {
            contractFilesView(params).then((res) => {
                if (res && +res.code === 0) {
                    window.open(res.data, '附件预览');
                }
            });
        };
        const getAdvanceValue = (val) => {
            filesForm.value.templateId = val.templateId;
            filesForm.value.templateName = val.templateName;
            filesForm.value.attachmentId = val.attachmentId;
            filesForm.value.draftModel = val.draftModel;
        };

        /**
     * @description: 清空文件类型时初始化联动表单模板数据
     */
        const filesTypeClear = (data) => {
            filesForm.value.templateId = '';
            filesForm.value.templateName = '';
            filesForm.value.attachmentId = '';
            filesForm.value.draftModel = '';
        };
        const fileTypeChange = (data) => {
            if (data !== 'ContractNeedStamp') {
                filesTypeClear();
            }
        };
        // 导入打开弹窗进行附件上传
        const upload = () => {
            const { contractCode, userCode, contractCharacter } = filesBasicParams;
            const { userName } = user;
            const { fileType, accessoryName } = filesForm.value;
            const urlObject = {
                contractCode,
                fileType,
                userName,
                userCode,
                accessoryName,
                contractCharacter
            };
            const urlParams = Object.keys(urlObject).reduce((pre, cur) => {
                if (urlObject[cur]) {
                    if (pre.length !== 1) {
                        pre = `${pre}&${cur}=${urlObject[cur]}`;
                    } else {
                        pre = `${pre}${cur}=${urlObject[cur]}`;
                    }
                }
                return pre;
            }, '?');
            const baseUrl = staticConfig.dialogImportConfig.ImportConfig.baseUrl;
            staticConfig.dialogImportConfig.ImportConfig.action = baseUrl.concat(urlParams);
            setupContext.refs.Import.orderImportC();
        };
        // 导入关闭弹窗
        const uploadClose = () => {
            closeLog();
        };
        const closeLog = () => {
            filesForm.value = {
                accessoryName: '',
                fileType: '',
                templateName: '',
                draftModel: ''
            };
            addDialog.value = false;
            isAfterUpload.value = true;
            subTabTable.getList();
        };
        const fileTypeItem = config.columns.find(item => item.prop === 'fileType');
        const getFileTypeDict = async () => {
            const res = await getDictDataApi({ dictCodes: 'SYS_CR_CONTRACT_FILE_TYPE' });
            if (+res.code === 0 && res.data && res.data['SYS_CR_CONTRACT_FILE_TYPE']) {
                const fileTypeDict = { ...res.data['SYS_CR_CONTRACT_FILE_TYPE'] };
                if (props.detailPageState !== STATE_OTHER_CHANGE && !isDisableAddBtn.value) {
                    fileTypeItem.optionsKey = null;
                    if ([1, 2, 4].includes(+props.detailData.contractCharacter)) {
                        // 合同为新签/续签/预签时，上传附件文件类型不能选择“补充协议、终止协议”
                        Reflect.deleteProperty(fileTypeDict, 'ChangeProtocol'); // 补充
                        Reflect.deleteProperty(fileTypeDict, 'TerminalProtocol'); // 终止
                        fileTypeItem.options = fileTypeDict;
                        return false;
                    }
                    if ([3].includes(+props.detailData.contractCharacter)) {
                        // 合同为补充协议时，上传附件文件类型不能选择“终止协议，主合同文件”
                        Reflect.deleteProperty(fileTypeDict, 'TerminalProtocol'); // 终止
                        Reflect.deleteProperty(fileTypeDict, 'ContractNeedStamp'); // 主合同
                        fileTypeItem.options = fileTypeDict;
                        return false;
                    }
                    if ([5].includes(+props.detailData.contractCharacter)) {
                        // 合同为终止协议时，上传附件文件类型不能选择“补充协议，主合同文件”
                        Reflect.deleteProperty(fileTypeDict, 'ChangeProtocol'); // 补充
                        Reflect.deleteProperty(fileTypeDict, 'ContractNeedStamp'); // 主合同
                        fileTypeItem.options = fileTypeDict;
                        return false;
                    }
                } else {
                    fileTypeItem.optionsKey = 'SYS_CR_CONTRACT_FILE_TYPE';
                }
            }
        };
        const updateBtchVisible = ref(false);
        const updateContractVisible = ref(false);
        const handleSyncData = () => {
            MessageBox.confirm('此操作将更新所选合同的附件数据，请确认', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const timer = setTimeout(() => {
                    updateBtchVisible.value = true;
                    clearTimeout(timer);
                }, MAGIC_NUMBER.THREE_HUNDRED);
            });
        };
        const selectedFiles = ref([]);
        const updateConfirmCb = (fileList = []) => {
            selectedFiles.value = fileList;
            // 打开合同列表选择弹框
            updateContractVisible.value = true;
            const advanceParams = _.cloneDeep(contractAdvanceParams);
            advanceParams.afterResponse = (res) => {
                res.data.list = res.data.list.filter(item => item.contractCode !== props.detailData.contractCode).map((item) => {
                    item.businessType = item.businessType ? item.businessType.split(',') : [];
                    const busiLabelList = item.businessType.map(v2 => busiTypeList.value[v2]);
                    _.set(item, 'busiLabelList', busiLabelList.join());
                    _.set(item, 'validTime', utils.formatDate(item.validTime));
                    _.set(item, 'failTime', utils.formatDate(item.failTime));
                    return item;
                });
                // _.set(res.data.list, totalData);
                const totalCount = res.data.totalCount ? res.data.totalCount - 1 : 0; // 剔除了一条选中的合同
                _.set(res.data, 'totalCount', totalCount);
                return res;
            };
            setupContext.refs.advanceMultiDialog.show(true, advanceParams, { contractGroupCode: props.detailData.contractGroupCode });
        };
        const detaliSearchSure = (data) => {
            updateBtchVisible.value = false;
            const contractEnclosureIds = selectedFiles.value.map(item => item.id);
            const updateContractCodes = data.map(item => item.contractCode);
            const params = {
                contractCode: props.detailData.contractCode,
                contractGroupCode: props.detailData.contractGroupCode,
                contractEnclosureIds,
                updateContractCodes
            };
            enclosureInfoUpdateBatch(params).then(res => {
                if (res?.code === '0') {
                    Message.success('批量更新成功');
                }
            }).finally(() => { subTabTable.getList(); });
        };
        const projectTypeLocal = ref('');
        watch(
            () => props.activeName,
            () => {
                getFileTypeDict();
            });
        watch(
            () => [props.detailPageState, props.detailData.contractStatus, props.activeName, props.detailData.contractCode,
                props.detailData.contractCharacter, props.detailData.projectCode, props.detailData.projectType],
            async (newVal) => {
                if (newVal[2] === 'filesDetail') {
                    const { userCode } = user;
                    filesBasicParams.contractCode = newVal[3];
                    filesBasicParams.userCode = userCode;
                    filesBasicParams.contractCharacter = newVal[4] + '';
                    // 判断页面状态是新增时不能新增编辑和删除，合同状态非草稿或新增时不能新增编辑和删除
                    if (newVal[0] === STATE_NEW) {
                        isDisableAddBtn.value = true;
                    } else {
                        isDisableAddBtn.value = props.detailPageState === STATE_OTHER_CHANGE || !(newVal[1] + '' === '0' || newVal[1] + '' === '1');
                        if (newVal[3]) {
                            subTabTable.getList();
                        } else {
                            subTabTable.totalData.value = [];
                            subTabTable.pageTotal.value = 0;
                        }
                    }
                    if (newVal[5] && !newVal[6]) {
                        getCdTsCustomerProjectsApi({ projectCode: newVal[5] }).then(res => {
                            if (+res.code === 0 && res.data.list && res.data.list.length) {
                                projectTypeLocal.value = res.data.list[0].projectType;
                            }
                        });
                    }
                }
            },
            { immediate: true }
        );

        const contractTempSearchSure = (data) => {
            const params = {
                accessoryName: subTabTable.totalData.value[tableIndex.value].accessoryName,
                templateName: data.templateName,
                fileType: subTabTable.totalData.value[tableIndex.value].fileType,
                draftModel: data.draftModel,
                templateId: data.templateId,
                attachmentId: data.attachmentId,
                contractCode: props.detailData.contractCode,
                templateProperties: data.templateProperties,
                templatePropertiesName: data.templatePropertiesName,
                userCode: user.userCode
            };
            createJDSKFile(params).then(res => {
                if (res && +res.code === 0) {
                    Message.success('生成主合同文件成功');
                    isAfterUpload.value = true;
                }
            }).finally(() => {
                subTabTable.getList();
            });
        };
        const busiTypeList = ref({});
        const contractTempCode = ref({});
        onMounted(async() => {
            const dictData = await getDictDataApi({ dictCodes: 'SYS_BMS_BUSI_TYPE,CRM_CONTRACT_TEMPLATE_CODE' });
            busiTypeList.value = dictData.data['SYS_BMS_BUSI_TYPE'];
            contractTempCode.value = dictData.data['CRM_CONTRACT_TEMPLATE_CODE'];
        });
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout();
        });
        return {
            isDisableAddBtn,
            config,
            actions,
            staticConfig,
            FilesDetailClass,
            subTabTable,
            addDialog,
            hasFilesType,
            uploadOrHangdleForm,
            filesForm,
            filesBasicParams,
            btnLoading,
            actionClick,
            getAdvanceValue,
            addNew,
            filesTypeClear,
            fileTypeChange,
            upload,
            uploadClose,
            closeLog,
            addDataArr,
            authorityCode,
            addNewLoading,
            updateBtchVisible,
            handleSyncData,
            updateConfirmCb,
            updateContractVisible,
            selectedFiles,
            detaliSearchSure,
            contractTempSearchSure
        };
    }
};
</script>
<style lang = "less" scoped>
.head-tips{
    margin-left: 8px;
    padding-bottom: 3px;
}
</style>
<style lang = "less">
.file-detail-subtab {
    .contract-file-import-dialog {
        .el-loading-spinner {
            top: 0;
            margin-top: 0;
            height: 100%;
            .circular {
                height: 26px;
                width: 26px;
            }
        }
    }
}
</style>
