<template>
    <div class="contract-list-father">
        <edit-table-box
            ref="editTableBox"
            :control="true"
            :allowRowClick="false"
            v-loading="subTabTable.tableLoading"
            :selection="config.selection"
            :columns="newColumns"
            :index="config.index"
            :height="config.height"
            highlight-current-row
            :tableData="subTabTable.totalData"
            :actions="actions"
            @selection-change="handleSelectedRow"
            @row-click="handleCurrentChange"
            @element-change="editChange"
            @btn-event="actionClick">
            <template #col-append="{ item, row, rIndex }">
                <el-tooltip v-if="item.prop === 'conditionsDetail'" class="item" effect="dark" :content="row.conditionsDetail || '--'" placement="top">
                    <lots-button type="text" :title="row.conditionsDetail" @click.stop="handleOpen(row)">详细说明</lots-button>
                </el-tooltip>
                <div v-if="item.prop === 'businessType'">{{row.busiLabelList}}</div>
            </template>
            <template #edit-col-append="{ item, row, rIndex }">
                <el-tooltip v-if="item.prop === 'conditionsDetail'" class="item" effect="dark" :content="row.conditionsDetail || '--'" placement="top">
                    <lots-button type="text" :title="row.conditionsDetail" @click.stop="handleOpen(row)">详细说明</lots-button>
                </el-tooltip>
            </template>
        </edit-table-box>
        <div class="footer">
            <lots-pagination
                @size-change="subTabTable.sizeChange"
                :current-page.sync="subTabTable.pageNo"
                @current-change="subTabTable.pageChange"
                size="small"
                layout="total, sizes, prev, pager, next, jumper"
                :total="subTabTable.pageTotal"
            />
        </div>
        <add-detail-dialog
            v-if="addDetailVisible"
            :row="theRow"
            @confirm="dialogConfirm"
            @handleClose="addDetailVisible = false">
        </add-detail-dialog>
    </div>
</template>

<script>
import lotsButton from '@/components/lots/lotsButton';
import { ref, reactive, computed, onMounted } from '@vue/composition-api';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import addDetailDialog from '@/modules/mdm/views/contractModules/contractGroupManage/detail/addDetailDialog/Index';
import { getDictDataApi, contractDelete } from '@mdm/api/contractManage/contractListApi.js';
import { byContractGroupCode, groupInfoSaveContract } from '@mdm/api/contractManage/contractGroupFootApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { STATE_NEW } from '@/modules/mdm/constant/contractManage.js';
import store from '@/store/index';
import { Message } from 'element-ui';
import utils from '@/components/utils/common.js';
const btnPermissionsArr = store.getters.btnPermissions;

export default {
    name: '',
    components: {
        lotsButton,
        lotsPagination,
        editTableBox,
        addDetailDialog
    },
    props: {
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            type: String,
            default: ''
        },
        activeName: {
            type: String,
            default: ''
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const EDIT_TYPE = {
            EDIT: 2,
            CANCEL: -3
        };
        const config = reactive(tableConfig);
        const actions = {
            fixedWidth: 110,
            list: [
                {
                    label: '保存',
                    event: 'saveItem',
                    displayRule: (row) => {
                        return props.detailPageState !== STATE_NEW && +row.edit === EDIT_TYPE.EDIT && ['0', '1'].includes(row.contractStatus + '') && btnPermissionsArr.includes('1000000009');
                    }
                },
                {
                    label: '删除',
                    event: 'delItem',
                    displayRule: (row) => {
                        return ['0', '1'].includes(row.contractStatus + '') && row.id && +row.edit === EDIT_TYPE.CANCEL && btnPermissionsArr.includes('10000000010');
                    }
                }
            ]
        };
        class DataDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                const { contractGroupCode } = props.detailData;
                condition.contractGroupCode = contractGroupCode;
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0 && res.data) {
                    this.totalData.value = res.data.list.map((item) => {
                        item.businessType = item.businessType ? item.businessType.split(',') : [];
                        const busiLabelList = item.businessType.map(v2 => busiTypeList.value[v2]);
                        item.busiLabelList = busiLabelList.join();
                        item.validTime && (item.validTime = utils.formatDate(item.validTime));
                        item.failTime && (item.failTime = utils.formatDate(item.failTime));
                        return {
                            saveItemLoading: false,
                            allowEdit: [0, 1].includes(+item.contractStatus),
                            edit: -3,
                            ...item,
                            customerType: item.customerType || item.customerType === 0 ? item.customerType + '' : '',
                            contractStatus: item.contractStatus || item.contractStatus === 0 ? item.contractStatus + '' : '',
                            conditionsDetail: item.conditionsDetail // 手工注册一下属性，解决编辑油价详细条款说明表格不同步渲染问题
                        };
                    });
                    this.pageNo.value = res.data.pageNo;
                    this.pageSize.value = res.data.pageSize;
                    this.pageTotal.value = res.data.totalCount;
                    setupContext.emit('handleCurrentMain', null); // 重置当前行
                }
            };
        }
        // 根据页面类型来获取对应的接口
        const subTabTable = new DataDetailClass({
            Api: {
                listApi: byContractGroupCode,
                deleteApi: contractDelete // 删除行接口
            }
        });
        const busiTypeList = ref({});
        onMounted(async() => {
            const dictData = await getDictDataApi({ dictCodes: 'SYS_BMS_BUSI_TYPE' });
            busiTypeList.value = dictData.data['SYS_BMS_BUSI_TYPE'];
            if (props.detailData.contractGroupCode) {
                getDataList();
            }
        });
        const newColumns = computed(() => {
            const columns = [];
            config.columns.forEach((item) => {
                if (item.prop === 'conditionsDetail') { // 油价为否时，去掉'详细条款说明'字段
                    +props.detailData.oilLinkedProportion === 1 && columns.push(item);
                    return;
                }
                if (item.prop === 'accountEntityName') {
                    item.disabled = ['3', '5'].includes(props.detailData.contractGroupCharacter + '');
                }
                columns.push(item);
            });
            (setupContext.refs.editTableBox && subTabTable.totalData.value.length)
                && setupContext.refs.editTableBox.$refs.moduleTable.doLayout(); // 编辑表格有数据时，解决表格错位
            return columns;
        });
        const actionClick = (row, event, index) => {
            if (event === 'delItem') {
                subTabTable.deleteRow({ ...row, businessType: row.businessType.length ? row.businessType.join(',') : '' });
                return false;
            } else if (event === 'saveItem') {
                if (setupContext.refs.editTableBox.saveItemData(row)) {
                    const { id, contractCode, contractName, accountEntityName, accountEntityId, validTime, failTime, businessType, customerType, conditionsDetail, version } = row;
                    const params = {
                        id,
                        contractCode,
                        contractName,
                        accountEntityName,
                        accountEntityId,
                        validTime,
                        failTime,
                        businessType: businessType.join(','),
                        customerType,
                        conditionsDetail,
                        contractGroupCode: props.detailData.contractGroupCode,
                        version // 后端用于防止多人编辑冲突
                    };
                    if (new Date(failTime).getTime() < new Date(validTime).getTime()) {
                        return Message.warning('失效时间不能早于生效时间');
                    }

                    row[`${event}Loading`] = true;
                    groupInfoSaveContract(params).then(res => {
                        if (+res.code === 0) {
                            Message.success('保存成功');
                            getDataList();
                        }
                    }).finally(() => {
                        row[`${event}Loading`] = false;
                    });
                }
            } else if (event === 'edit') {
                autoFillcustomerType(row); // 根据业务类型初始化仓储运作类型字段
                setupContext.emit('handleEdit', row);
            } else if (event === 'cancel') {
                setupContext.emit('handleCancel', subTabTable.totalData.value);
            }
        };
        const addRow = () => {
            subTabTable.totalData.value.push({ // 备注：要给出表格行的初始属性，不然会出现无法实时监控渲染属性变化值的情况
                contractCode: '',
                contractName: '',
                accountEntityName: '',
                accountEntityId: '',
                validTime: '',
                failTime: '',
                allowEdit: true,
                edit: 2,
                businessType: '',
                customerType: '',
                conditionsDetail: '',
                contractStatus: '0',
                saveItemLoading: false
            });
        };
        const currentRow = ref(null);
        const handleCurrentChange = (row) => {
            if (row && JSON.stringify(row) !== '{}') {
                currentRow.value = row;
            } else {
                currentRow.value = null;
            }
            setupContext.emit('handleCurrentMain', currentRow.value);
        };
        const getDataList = () => {
            subTabTable.getList();
            currentRow.value = null; // 重置当前行
        };
        const handleSelectedRow = () => {
            // 123
        };
        const editChange = ({ prop, val, type, row, index }) => {
            if (prop === 'businessType' && row.businessType.length) {
                autoFillcustomerType(row);
            }
        };
        // 仓储运作类型字段值/状态自动控制函数
        const autoFillcustomerType = (row) => {
            if (row.businessType.length) {
                const hasCangChu = row.businessType.some((item) => item === '1');
                const isReplenishOrStop = ['3', '5'].includes(props.detailData.contractGroupCharacter + ''); // 补充、终止协议，禁用会计主体
                // hasCangChu && Message.info('业务类别包含仓储时，仓储运作类型必填');
                row.disableProps = hasCangChu
                    ? ['contractStatus', (isReplenishOrStop ? 'accountEntityName' : null)]
                    : ['customerType', 'contractStatus', (isReplenishOrStop ? 'accountEntityName' : null)]; // 不含仓储，禁用仓储运作类型
                !hasCangChu && (row.customerType = ''); // 不含仓储，清空仓储运作类型
            }
        };
        const theRow = ref({});
        const handleOpen = (row) => {
            if (row.edit === EDIT_TYPE.CANCEL) {
                Message.warning([0, 1].includes(+row.contractStatus) ? '请点击编辑按钮开启编辑状态进行编辑' : '非草稿、新增状态，不允许编辑');
                return false;
            }
            theRow.value = row;
            addDetailVisible.value = true;
        };
        const addDetailVisible = ref(false);
        const dialogConfirm = (data) => {
            theRow.value.conditionsDetail = data;
            addDetailVisible.value = false;
        };
        return {
            config,
            actions,
            subTabTable,
            getDataList,
            newColumns,
            handleCurrentChange,
            handleSelectedRow,
            editChange,
            handleOpen,
            addDetailVisible,
            dialogConfirm,
            theRow,
            addRow,
            actionClick
        };
    }
};
</script>
<style lang="less" scope>
.contract-list-father {
    .el-table__body-wrapper{
        height: auto!important;
    }
}
</style>
