import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 合同组id查询详情接口
export const groupInfoGetById = (params) => {
    return request({
        url: apiCrmHost + '/contract/group/info/getById',
        method: 'get',
        params
    });
};
// 合同组保存接口
export const groupInfoAdd = (data) => {
    return request({
        url: apiCrmHost + '/contract/group/info/add',
        method: 'post',
        data
    });
};
// 提交审批接口
export const groupInfoCreateFlowApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/group/info/createFlow',
        method: 'post',
        data
    });
};
// 法务归档重试
export const groupInfoSubmitApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/group/info/submit',
        method: 'post',
        data
    });
};
